import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withFormik, FormikProps } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { IBidInfoForm, IWarningModal } from '../../interfaces';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { setCookie } from 'core/utils/cookiesUtils';
import {
  defaultValues,
  defaultPostPayload as payload,
} from 'modules/shared/bidDefaultValues';
import { ANAGO_CLEANSOURCE_DEV_LOGIN_URL } from 'core/utils/constants';
import { parseDefaultCustomizeTermsPayloadFromServer } from 'modules/BidInfoForm/services/utils';
import { parseDefaultTerms } from 'modules/BidInfoForm/parser';
import { FormProvider, GenericModal, RenderPdf, Button } from 'core/components';
import ActionAlertModal from 'core/components/ActionAlertModal';
import { getQueryString, axiosInstance } from 'core/utils';
import { stripHTMLTags, decodeEntities, isWebView } from 'core/utils';
import { getBaseURL, getBidURL } from '../../utils';
import { parseFromApi, parseGetToPost, parseWaterStreet } from '../../parser';
import { IAPIResponse } from '../../interfaces';
import EventEmitter from 'core/utils/events';
import {
  postBidInfo,
  uploadOneTimeAttachment,
  getBidInfoById,
  getFranchiseById,
  saveDraft,
  saveTemplate,
  checkIsGeneratingPDF,
  isAlive,
  isTabIdValid,
  getBlankPdfUrl,
  autoSave,
  loadTemp,
  loadNonTemp,
  getByUniqueIDNew,
  updateBid,
  verifyServerUnavailable,
} from '../../services/bidInfoService';

import {
  getSignNowStatus,
  setInvalidDocument,
} from '../../services/bidInfoServiceClient';

import { LoadingPage, BlockingOverlay } from '../../components/organisms';
import { toast } from 'react-toastify';

import {
  AccountInfo,
  AdditionalInformation,
  ServiceArea,
  RestroomFixtures,
  ProductionRates,
  DailyManHours,
  CleaningSpecification,
  ProtectionDisinfectionPlans,
  CancellationClause,
  DayPorter,
  Supplies,
  References,
  BidInformation,
} from './sections';

import { useActivityTracking } from '../../../../core/hooks/useActivityTracking';

import {
  Header,
  ClientInfo,
  RoomInventory,
  Footer,
  AdditionalQuotes,
  ConfirmationModal,
  FloorTypeTotals,
} from 'modules/shared/sections';

import { BidFormMenuScreen } from 'modules/shared/Menu';

import './styles.scss';
import {
  validateForm,
  validateSaveTemplate,
} from '../../../../core/validators/BidInfoFormValidator';
import SignNowScreen from '../SignNowScreen';
import BlockingPopupModal from '../BlockingPopupModal';
import { SectionGroup } from './sections/SectionGroup';
import { useAuthenticationHandler } from '../../../../core/hooks/useAuthenticationHandler';
import { useGenericErrorHandler } from '../../../../core/hooks/useGenericErrorHandler';
import { getCookie } from 'core/utils/cookiesUtils';
import LoadTemplateModal from './sections/LoadTemplateModal';

sessionStorage.removeItem('sessionStorageCleaningSpecification');

const compareValues = (v1, v2) => {
  const orderedV1 = {},
    orderedV2 = {};

  Object.keys(v1)
    .sort()
    .forEach((key) => {
      if (
        key != 'uid' &&
        key != 'bidInfoId' &&
        key != 'originalBidUid' &&
        key != 'isTemp'
      ) {
        orderedV1[key] = v1[key];
      }
    });

  Object.keys(v2)
    .sort()
    .forEach((key) => {
      if (
        key != 'uid' &&
        key != 'bidInfoId' &&
        key != 'originalBidUid' &&
        key != 'isTemp'
      ) {
        orderedV2[key] = v2[key];
      }
    });
  const equal = JSON.stringify(orderedV1) == JSON.stringify(orderedV2);
  return equal;
};

type BidFormType = FormikProps<IBidInfoForm> & RouteComponentProps;

const BidForm = ({
  values,
  setFieldValue,
  setValues,
  dirty,
  ...props
}: BidFormType) => {
  const { search } = props.location;
  const uniqueId = getQueryString(search, 'formUniqueId');
  const franchiseId = getQueryString(search, 'franchiseId');
  const [showExit, setShowExit] = useState(false);
  const [ready, setReady] = useState(false);
  const [tabId, setTabId] = useState(new Date().toISOString());
  const setAllValues = (params: any) => {
    return setValues({ ...values, ...params });
  };
  const [bidData, setBidData] = useState({ id: '' });
  const [bidFullData, setBidFullData] = useState({});
  const [errors, setErrors] = useState({});
  const [showLoadTemplateModal, setShowLoadTemplateModal] = useState(false);
  const [warningModal, setWarningModal] = useState<IWarningModal>(null);
  const [blockingModal, setBlockingModal] = useState<IWarningModal>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hadChanges, setHadChanges] = useState(false);
  const [showConfirmation, setShowConfirmationModal] = useState(false);
  const [showMenu, setShowMenu] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [showAlreadySignedModal, setShowAlreadySignedModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showExpiredBid, setShowExpiredBid] = useState(false);
  const [needsSaving, setNeedsSaving] = useState(false);
  const [franchiseData, setFranchiseData] = useState(null);
  const [showNoBidUniqueID, setShowNoBidUniqueID] = useState(false);
  const [successModalData, setSuccessModalData] = useState({
    emails: '',
    emailBody: '',
    location: props.location,
    formUniqueId: '',
    franchiseId: '',
    bidLink: '',
  });
  const logSentry = useActivityTracking(() => values);

  const [autoSaveTimeout, setAutoSaveTimeout] = React.useState(1);
  const [executeAutoSave, setExecuteAutoSave] = React.useState(null);
  const [tempData, setTempData] = React.useState({});
  const [completeButtonEnabled, setCompleteButtonEnabled] = useState(true);
  const [blockAutoSave, setBlockAutoSave] = React.useState(false);
  const [showConnectionWarning, setShowConnectionWarning] =
    React.useState(false);
  const [showEmptyPidWarning, setShowEmptyPidWarning] = React.useState(false);

  const [showAutoPidTooltip, setShowAutoPidTooltip] = React.useState(false);
  const [newForm, setNewForm] = React.useState(false);

  const { userSignNowStatus } = values;
  const [userStatus, setUserStatus] = React.useState(null);
  const shouldShowSignNowModal = userStatus !== null && userStatus !== 2;

  const { state = {} } = props.location;
  const userId = getQueryString(window.location.href, 'userId');

  const { error, setError } = useAuthenticationHandler();
  const { error: genericError, setError: setGenericError } =
    useGenericErrorHandler();

  // NEW REFACTOR
  const [userData, setUserData] = useState<any>(); // NEED REFACTOR

  // User Role used on Footer NEED REFACTOR
  const isUserRole =
    userData &&
    userData.Roles &&
    !userData.Roles.includes('Super Admin') &&
    !userData.Roles.includes('Master User') &&
    !userData.Roles.includes('Corporate Admin') &&
    !userData.Roles.includes('Corporate User') &&
    !userData.Roles.includes('Super Master');

  React.useEffect(() => {
    if (window?.location?.pathname === '//') {
      window.location.pathname = '/';
    }
  }, []);

  React.useEffect(() => {
    const fetch = async () => {
      if (userId && !userData) {
        const userData = await axiosInstance.get(
          `GetUserById?userid=${userId}`
        );
        setUserData(userData.data);
      }
    };
    fetch();
  }, [userId, userData]);

  // Check Franchise ID if we have bid data, if not, we redirect, this can be done at the get code...
  // NEED REFACTOR
  React.useEffect(() => {
    if (!franchiseId && bidFullData.FranchiseId) {
      props.history.push(
        `${props.location.pathname}${props.location.search}&franchiseId=${bidFullData.FranchiseId}`
      );
    }
  }, [franchiseId, bidFullData.FranchiseId]);

  // Error Handling for Unauthorized NEED REFACTOR
  React.useEffect(() => {
    if (!!error && error.status == 409) {
      return handleError(error);
    }
    if (!!error && error.status == 401) {
      setIsLoading(false);
      setWarningModal({
        title: 'Access Denied',
        message:
          'You do not have permissions to access this form. Please contact Anago Support if you believe you have reached this in error.',
        proceed: () => {
          setCookie('anago_redirect_after_login', window.location.href);
          window.location.href = process.env.REACT_APP_BACKEND_URL.replace(
            'login',
            'home'
          );
          // setWarningModal(null);
          // setError(undefined)
        },
      });
    } else if (!!error && error.status == 403) {
      try {
        if (error.data.includes('CLIENT_VERSION_MISMATCH')) {
          setIsLoading(false);
          setWarningModal({
            title: 'Attention',
            message:
              'Seems like your app is outdated. This may happen due to browser cache issues. Click OK to get the latest version from server.',
            proceed: () => {
              window.location.reload(true);
            },
          });
        }
      } catch (e) {
        setGenericError(e);
      }
    }
  }, [error]);

  // Generic Error Handling... Really NEED REFACTOR
  React.useEffect(() => {
    if (!!genericError) {
      setIsLoading(false);
      logSentry(genericError, values);
      setWarningModal({
        title: 'Attention',
        message:
          'An unexpected error has occurred. Please contact AFISupport with Error ID: ' +
          sessionStorage.getItem('transaction_id'),
        proceed: () => {
          setWarningModal(null);
          setGenericError(undefined);
        },
      });
    }
  }, [genericError]);

  // END REFACTOR

  // This is supposed to duplicate the proposal... NEED REFACTOR
  React.useEffect(() => {
    if (state.formValues) {
      setTimeout(() => {
        setAllValues(state.formValues);
        toast.info('The proposal was duplicated successfully.');
        window.scrollTo(0, 0);
      }, 2000);
    }
  }, [state]);

  // Handle Error method NEED REFACTOR
  const handleError = (e, showBanner = false) => {
    const msg =
      e && e.response && e.response.data && typeof e.response.data == 'string'
        ? e.response.data
        : undefined;

    if (
      e &&
      e.response &&
      (e.response.status == '401' || e.response.status == '409')
    ) {
      setBlockingModal({
        message: msg || e.response.status,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else if (e && e.response && e.response.status == '503') {
      setWarningModal({
        message: msg || e.response.status,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else {
      if (showBanner) {
        setShowConnectionWarning(true);
      } else {
        setWarningModal({
          message:
            "It looks like you don't have internet at the moment, please ensure you have internet access and try again.",
          proceed: () => {
            setWarningModal(null);
          },
        });
      }
    }
  };

  // BREAKPOINTS for window NEED REFACTOR
  const [minorThan768, setMinorThan768] = React.useState(false);

  const setBreakpoints = () => {
    setMinorThan768(window.innerWidth < 768);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(() => {
    const message =
      'This form is designed to be rendered on iPad sized screens or larger. Utilizing this form on a smaller screen will impact its usability. It is strongly recommended to change to larger device before proceeding.';
    if (minorThan768) {
      setWarningModal({
        message: message,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else {
      if (warningModal && warningModal.message == message) {
        setWarningModal(null);
      }
    }
  }, [minorThan768]);
  // END BREAKPOINTS

  // Open Search showMenu with some clears
  const onOpenSearch = () => {
    clearTimeout(autoSaveTimeout);
    setAutoSaveTimeout(null);
    setExecuteAutoSave(null);
    setReady(false);

    setShowMenu({
      renderDraftSection: true,
      renderSentSection: true,
      renderSignedSection: true,
      renderTemplateSection: true,
    });
  };

  // Why we have a useState here????? NEED REFACTOR
  // User signNowStatus...
  React.useEffect(() => {
    if (
      userSignNowStatus != null &&
      userSignNowStatus != undefined &&
      userSignNowStatus != userStatus
    ) {
      setUserStatus(userSignNowStatus == 1 ? 2 : userSignNowStatus);
    }
  }, [userSignNowStatus]);

  // END OF  signNowStatus

  // Check if we are opening pdf baased at URL... NEED DELETE
  React.useEffect(() => {
    const openingPDF =
      getQueryString(window.location.href, 'openingPDF') === 'true';

    if (isWebView() && openingPDF) {
      // This should not happen anymore...
      props.history.goBack();
    }
  }, [props.history]);
  // END OF open PDF on Xamarin...

  // Method for delete using warning modal NEED REFACTOR
  const showDeleteConfirmationModal = (
    proceedFunction: Function,
    isPlural: boolean = false
  ) => {
    setWarningModal({
      message: isPlural
        ? 'Are you sure you want to delete these items?'
        : 'Are you sure you want to delete this item?',
      proceed: () => {
        proceedFunction();
        setWarningModal(null);
      },
      dismiss: () => setWarningModal(null),
    });
  };
  // END for warning modal

  // Method for blank PDF

  const onClickDownloadBlankPdf = async (bidId) => {
    const pdfUrl = await getBlankPdfUrl(bidId);
    const w = window.open(pdfUrl.data, '_blank');
    if (isWebView()) {
      setTimeout(() => {
        w.close();
      }, 100);
    }
  };

  // END FOR blank pdf opening

  // Need to check what this method is doing, now we are just using this at Opening Template... and override everything on state using the template values...
  const navigateToForm = async ({ BidUniqueID }) => {
    const data = await loadNonTemp(BidUniqueID);
    const {
      BidUniqueID: bidUniqueId,
      CreatedBy: createdBy,
      FlgSupply: flgSupply,
      FlgReference: flgReference,
      ...Data
    } = data.data.Data;
    setBidData({
      ...bidData,
      ...Data,
      BidUniqueID: bidFullData.BidUniqueID,
      CreatedBy: bidFullData.CreatedBy,
      FlgSupply: bidFullData.FlgSupply,
      FlgReference: bidFullData.FlgReference,
      ExpirationDays: values.expirationDays,
    });
    setBidFullData({
      ...bidFullData,
      ...Data,
      BidUniqueID: bidFullData.BidUniqueID,
      CreatedBy: bidFullData.CreatedBy,
      FlgSupply: bidFullData.FlgSupply,
      FlgReference: bidFullData.FlgReference,
      ExpirationDays: values.expirationDays,
    });
    setShowLoadTemplateModal(false);
    const newValues = parseFromApi(data.data['Data']);
    setAllValues({
      ...newValues,
      uid: values.uid,
      BidUniqueID: bidFullData.BidUniqueID,
      BidInformationSheetSupplys: Data.BidInformationSheetSupplys,
      FlgSupply: bidFullData.FlgSupply,
      FlgReference: bidFullData.FlgReference,
      BidInformationSheetReferences: Data.BidInformationSheetReferences,
      expirationDays: values.expirationDays,
      pid: values.pid,
      sid: values.sid,
      control: values.control,
      bidDate: values.bidDate,
      businessName: values.businessName,
      address: values.address,
      suite: values.suite,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      phone: values.phone,
      contactPhoneExtension: values.contactPhoneExtension,
      county: values.country,
      decisionMakerFirstName: values.decisionMakerFirstName,
      decisionMakerLastName: values.decisionMakerLastName,
      walkThruFirstName: values.walkThruFirstName,
      walkThruLastName: values.walkThruLastName,
      decisionMakerTitle: values.decisionMakerTitle,
      walkThruTitle: values.walkThruTitle,
      decisionMakerEmail: values.decisionMakerEmail,
      walkThruEmail: values.walkThruEmail,
      decisionMakerPhoneType: values.decisionMakerPhoneType,
      decisionMakerPhone: values.decisionMakerPhone,
      decisionMakerPhoneExtension: values.decisionMakerPhoneExtension,
      walkThruPhoneType: values.walkThruPhoneType,
      walkThruPhone: values.walkThruPhone,
      walkThruPhoneExtension: values.walkThruPhoneExtension,
      proposalAddressedTo: values.proposalAddressedTo,
      sic: values.sic,
      isCityTemplate: values.isCityTemplate,
      isTemplate: values.isTemplate,
      roomInventory: newValues.inventory || [],
      sentStatus: values.sentStatus,
      terms: null,
      physicalURL: values.physicalURL,
      externalId: bidFullData.ExternalId,
      createdBy: bidFullData.CreatedBy,
    });
  };

  // Recheck template params...

  const processBid = (foundValues, askIfTemplate) => {
    const urlTabId = getQueryString(search, 'tabId');

    const validTabId = urlTabId || tabId;

    setBidFullData(foundValues.data['Data']);
    const parsedValues = parseFromApi(foundValues.data['Data']);
    const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';

    if (!parsedValues.pid) {
      setShowAutoPidTooltip(true);
    }
    if (askIfTemplate && parsedValues.isTemplate) {
      const askForTemplateMode = () =>
        setWarningModal({
          title: 'Template',
          message:
            'Do you want to edit or start a new draft based on the current template?',
          proceed: async () => {
            setIsLoading(true);
            setBlockAutoSave(true);
            setExecuteAutoSave(null);
            setReady(false);
            parsedValues.tabId = new Date().toISOString();
            const saveDraftResponse = await saveDraft(parsedValues, userId);

            window.location.replace(
              `#${
                parsedValues.isSpecialty ? '/specialty-form' : '/bid-info-form'
              }?userId=${userId}&formUniqueId=${
                saveDraftResponse.data.id
              }&franchiseId=${franchiseId}&tabId=${
                parsedValues.tabId
              }&v=${Math.random().toString(36).substring(7)}`
            );
            // setIsLoading(true);
            // setWarningModal(null)
          },
          dismiss: () => {
            setIsLoading(true);
            setWarningModal(null);
            fetch(false);
          },
          confirmText: 'NEW DRAFT',
          dismissText: 'EDIT TEMPLATE',
        });

      if (parsedValues.isCityTemplate) {
        if (!isCityUser) {
          // setError({status: 401})
          // return;
          setIsLoading(false);
          setWarningModal({
            title: 'Template',
            message: 'This will crate a new draft based on your City template',
            proceed: async () => {
              setIsLoading(true);
              setBlockAutoSave(true);
              setExecuteAutoSave(null);
              setReady(false);

              parsedValues.tabId = new Date().toISOString();
              const saveDraftResponse = await autoSave(parsedValues, userId);
              window.location.replace(
                `#${
                  parsedValues.isSpecialty
                    ? '/specialty-form'
                    : '/bid-info-form'
                }?userId=${userId}&formUniqueId=${
                  saveDraftResponse.data.id
                }&franchiseId=${franchiseId}&tabId=${
                  parsedValues.tabId
                }&v=${Math.random().toString(36).substring(7)}`
              );
              // setIsLoading(true);
              // setWarningModal(null)
            },
          });
        } else {
          setIsLoading(false);
          askForTemplateMode();
        }
      } else {
        setIsLoading(false);
        askForTemplateMode();
      }
      return;
    }

    let userHasBetaOptIn = false;
    let userSignNowStatus = -1;

    if (userData['IsOptIn'] != null && userData['IsOptIn'] != undefined) {
      userHasBetaOptIn = userData['IsOptIn'];
    }

    userSignNowStatus =
      userData['SignatureProviderStatus'] != null
        ? userData['SignatureProviderStatus']
        : -1;

    const openForm = (tempValues = false) => {
      const v = tempValues || parsedValues || {};
      setIsLoading(true);
      setTimeout(() => {
        setAllValues({
          ...v,
          userSignNowStatus,
          userHasBetaOptIn,
        });
        setNeedsSaving(false);

        setIsLoading(false);
        // if (tempValues && v.status !== 2) {
        //   toast.info('Draft Auto-Save Enabled');
        //   startAutoSaveTimeout();
        // }
        setTimeout(() => {
          setAutoSaveTimeout(1);
          setHadChanges(false);
          setReady(true);
          EventEmitter.emit('VALIDATE_FIELDS', '');
        }, 1500);
      }, 1000);

      setShowAlreadySignedModal(v.status === 2);
      setShowExpiredBid(v.isExpired && v.status != 2);
      setWarningModal(null);
    };

    const continueFlow = async () => {
      if (foundValues.data['Data']['Status'] != 2) {
        try {
          if (foundValues.data['Data']['BidUniqueID'] && validTabId) {
            await isTabIdValid(
              foundValues.data['Data']['BidUniqueID'],
              validTabId
            );
          } else {
            await isAlive();
          }
        } catch (e) {
          setTimeout(() => {
            handleError(e);
          }, 0);
          openForm();
          logSentry(e, values);
          return;
        }
      }

      const tempValues = foundValues.data['DataTemp'];
      // Here we check if we have a temporary

      if (
        tempValues &&
        foundValues.data['Data']['Status'] != 2 &&
        !foundValues.data['Data']['IsTemplate']
      ) {
        setIsLoading(false);
        setWarningModal({
          message:
            'We found a draft for this form on the server. Do you want to start from where stopped?',
          proceed: () => {
            setIsLoading(true);

            const parsedTempValues = tempValues
              ? parseFromApi(tempValues)
              : null;

            openForm({
              ...parsedTempValues,
              uid: parsedValues.uid,
              bidInfoId: parsedValues.bidInfoId,
              isTemp: parsedValues.isTemp,
              originalBidUid: parsedValues.originalBidUid,
              sentStatus: parsedValues.sentStatus,
              isTemplate: !!parsedValues.isTemplate,
              isAutoSave: false,
            });

            setTempData(parsedTempValues);
            // setShowAutoPidTooltip(true);
          },
          dismiss: async () => {
            setIsLoading(true);

            setTempData(null);

            setWarningModal(null);

            openForm();
          },
          confirmText: 'YES',
          dismissText: 'NO',
        });
      } else {
        if (!!parsedValues.sentStatus) {
          openForm();
        } else {
          openForm();
          setTimeout(() => {
            setTempData(null);
            // setShowAutoPidTooltip(true);
            setAutoSaveTimeout(1);
          }, 1500);
        }
      }
    };

    if (parsedValues.bidVersion != 2 && parsedValues.status != 2) {
      setIsLoading(false);
      setWarningModal({
        title: 'WARNING: Removing Bid Overrides',
        message:
          'This proposal was created using previous version of Anago CleanSource®. Editing this proposal will clear any Bid Overrides entered previously. This action cannot be undone and these Overrides will be lost. Please download a copy of the PDF for reference before proceeding.',
        proceed: () => {
          onClickDownloadBlankPdf(parsedValues.uid);
        },
        dismiss: () => {
          continueFlow();
          // openForm();
        },
        confirmText: 'Download PDF',
        dismissText: 'Continue',
        isDismissPrimary: true,
      });
    } else {
      continueFlow();
      // openForm();
    }
  };

  // BEGIN BID FLOW
  React.useEffect(() => {
    // No user data... ok
    if (!userData) return;

    // Check if we still use formId... maybe not
    const formId = getQueryString(search, 'formId');
    // Check if we still use tabId... maybe not
    const urlTabId = getQueryString(search, 'tabId');

    // Fallback for urlTabId...
    if (!!urlTabId) {
      setTabId(urlTabId);
    } else {
      setTabId(new Date().toISOString());
    }
    const validTabId = urlTabId || tabId;
    // end fallback

    // get the BID
    const fetch = async (askIfTemplate = true) => {
      if (!franchiseId) return;
      const goGetIt = uniqueId
        ? () => getByUniqueIDNew(uniqueId)
        : () => getBidInfoById(formId);

      let foundValues;
      try {
        await isTabIdValid(uniqueId, tabId);
        foundValues = await goGetIt();
        processBid(foundValues, askIfTemplate);
      } catch (err) {
        if (err.status === 300) {
          setIsLoading(false);
          setWarningModal({
            message:
              'We found a draft for this form on the server. Do you want to start from where stopped?',
            proceed: async () => {
              setIsLoading(true);
              const getDraft = await loadTemp(uniqueId);
              processBid(getDraft, askIfTemplate);
            },
            dismiss: async () => {
              setIsLoading(true);
              const getBid = await loadNonTemp(uniqueId);
              processBid(getBid, askIfTemplate);
            },
            confirmText: 'YES',
            dismissText: 'NO',
          });
        }
        if (err.status === 409) {
          handleError(err);
          setIsLoading(false);
        }
      }
      // set State with fullData
    };

    if (uniqueId || formId) {
      try {
        fetch().catch((e) => {
          logSentry(e, { uid: uniqueId || formId });
          if (e.status === 500) {
            props.history.push(`/forms/unable-to-open`);
          }
        });
      } catch (e) {
        setIsLoading(false);
        setWarningModal({
          message:
            'Something wrong happened with this proposal. Please, contact your support.',
          proceed: () => {
            setWarningModal(null);
          },
          dismiss: () => {
            setWarningModal(null);
          },
          confirmText: 'Download PDF',
          dismissText: 'Continue',
          isDismissPrimary: true,
        });
      }
      setNewForm(false);
    } else {
      // We should redirect the user here because it's not allowed anymore
      setShowNoBidUniqueID(true);

      setTempData(null);
      setAutoSaveTimeout(null);
      setShowAutoPidTooltip(true);
      setNewForm(true);
    }
  }, [search, userData]);

  React.useEffect(() => {
    const hasProtectionCleaningSpecs =
      Object.keys(values.cleaningFrequency).filter(
        (k) =>
          values.cleaningFrequency?.[k]?.items?.filter((item) =>
            item?.title?.includes?.('Anago Protection+ Disinfection')
          )?.length > 0
      ).length > 0;

    if (
      (!values.bidVersion || values.bidVersion != 2) &&
      hasProtectionCleaningSpecs &&
      values.status !== 2
    ) {
      setWarningModal({
        title: 'WARNING: Protection+ Change',
        message:
          'This proposal contains Protection+ Disinfection® Specifications from the previous version of CleanSource. To use the new Protection+ Disinfection® feature with this proposal, you must first remove Protection+ specifications from the Cleaning Specs section.',
        proceed: () => {
          setWarningModal(null);
        },
      });
    }
  }, [values.bidVersion]);

  React.useEffect(() => {
    if (franchiseId) {
      getFranchiseById(franchiseId).then(({ data }) => {
        setFranchiseData(data);
        setTimeout(() => {
          if (newForm) {
            setHadChanges(false);
            setReady(true);
          }
        }, 1000);
      });
    }
  }, [franchiseId, newForm]);

  const confirmationProps = {
    isOpen: showConfirmation,
    setWarningModal,
    showDeleteConfirmationModal,
    onSaveButtonClick: async (data) => {
      setIsLoading(true);
      let serverData = await loadNonTemp(bidData.id);
      serverData = serverData.data['Data'];
      const values = parseGetToPost(serverData);
      let referenceData = [];
      let supplyData = [];
      const referenceDetailsData =
        data?.BidInformationSheetReferences?.[0]?.ReferenceDetails?.filter(
          (item) => item.Default
        );

      if (data?.BidInformationSheetReferences?.[0]) {
        referenceData = [
          {
            ...data.BidInformationSheetReferences[0],
            ReferenceDetails: referenceDetailsData,
          },
        ];
      }
      const supplyDetailsData =
        data?.BidInformationSheetSupplys?.[0]?.SupplyDetails?.filter(
          (item) => item.Default
        );

      if (data?.BidInformationSheetSupplys?.[0]) {
        supplyData = [
          {
            ...data.BidInformationSheetSupplys[0],
            SupplyDetails: supplyDetailsData,
          },
        ];
      }

      const obj = {
        ...values,
        GenerateBlankPDF: false,
        GenerateAttachmentsPDF: true,
        SelectedAttachments: data.attachments.others,
        SelectedSystemAttachments: data.attachments.systemAttachments,
        SelectedFranchiseAttachments: data.attachments.franchiseAttachments,
        Supply: data.Supply,
        Reference: data.Reference,
        FlgProtectionDisinfectionPlan: data.FlgProtectionDisinfectionPlan,
        FlgSupply: data.FlgSupply,
        FlgSupplyCustomize: data.FlgSupplyCustomize,
        BidInformationSheetSupplys: supplyData,
        FlgReference: data.FlgReference,
        IsTemplate: !!data.IsTemplate,
        IsCityTemplate: !!data.IsCityTemplate,
        FlgReferenceCustomize: data.FlgReferenceCustomize,
        BidInformationSheetReferences: referenceData,
        BidInformationSheetSupplyId: data.BidInformationSheetSupplyId,
        CreatedBy: serverData['CreatedBy'] || userId,
      };

      if (data.attachments) {
        if (
          data.attachments.others.filter((i) => i != '' && i != 'industry-na')
            .length === 0 &&
          data.attachments.systemAttachments.length === 0 &&
          data.attachments.franchiseAttachments.length === 0
        ) {
          obj.GenerateAttachmentsPDF = false;
        }
      }

      if (Object.keys(data.attachments.oneTimeAttachment).length > 0) {
        await uploadOneTimeAttachment(
          bidData.id,
          data.attachments.oneTimeAttachment
        );
        obj.IncludeOneTimeAttachment = true;
      }

      const postResponse: IAPIResponse = await postBidInfo(obj);
      const formInfo: IAPIResponse = await loadNonTemp(bidData.id);
      const formInfoData = formInfo.data['Data'];
      const emails = data.emailProposal;
      const emailBody = decodeEntities(stripHTMLTags(formInfoData['BidEmail']));

      setBidData(postResponse.data as any);
      setSuccessModalData({
        ...successModalData,
        emails,
        emailBody,
        history: props.history,
        bidLink: formInfoData['BidLink'],
        franchiseId: formInfoData['FranchiseId'],
        formUniqueId: postResponse.data.id,
      });
      setShowSuccessModal(true);
      setIsLoading(false);
    },

    onBackButtonClick: async (data, terms) => {
      try {
        if (values.uid && tabId) {
          await isTabIdValid(values.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e);
        logSentry(e, values);
      }

      setBlockAutoSave(true);
      setReady(false);

      setFieldValue('terms', terms);

      setTimeout(() => {
        setBlockAutoSave(false);
        setReady(true);
        setShowConfirmationModal(false);
      });
    },

    onCustomizeTermsButtonClick() {},

    success: showSuccessModal,

    successModalData,

    bidData,

    bidFullData,

    franchiseId: franchiseId,

    franchiseData: franchiseData,
  };

  const doPost = async (
    values,
    callback = () => undefined,
    showToastr = true
  ) => {
    const response = await updateBid({
      values,
      userId,
      showToastr,
    });

    if (response.error) {
      setWarningModal({
        message: response.error as string,
        proceed: () => {
          setWarningModal(null);
        },
      });
      return false;
    }

    callback();

    if (response.data) {
      // needs refactoring
      if (values.isDuplicate) {
        window.location.replace(
          `/#/bid-info-form?userId=${userId}&formUniqueId=${
            response.data.id
          }&franchiseId=${franchiseId}&tabId=${values.tabId}&v=${Math.random()
            .toString(36)
            .substring(7)}`
        );
        setWarningModal(null);
        window.location.reload();
        return;
      }
      if (!values.isTemplate && !values.isCityTemplate) {
        const resp = await loadNonTemp(response.data.id);
        setBidFullData(resp.data.Data);
        setFieldValue('uid', response.data.id);

        setAllValues({
          ...values,
          uid: response.data.id,
          sentStatus: true,
          isTemplate: false,
        });

        setBidData(response.data as any);
        setSuccessModalData({
          ...successModalData,
          formUniqueId: response.data.id,
          userId,
        });
      }
      return response.data;
    }
  };

  const openProposalPDF = () => {
    setShowPdf(true);
  };

  const clearCleaningInconsistentValues = (values) => {
    Object.keys(values.cleaningFrequency).map((key) => {
      const cf = values.cleaningFrequency[key];
      const hasValidCa =
        cf.items.filter(
          (ca) =>
            ca?.title?.trim() !== '' &&
            ca?.frequencyTypes?.filter((ft) => ft.value.trim() != '').length > 0
        ).length > 0;
      const invalidCas = cf.items.filter(
        (ca) =>
          ca?.title?.trim() === '' &&
          ca?.frequencyTypes?.filter((ft) => ft.value.trim() === '').length ==
            ca.frequencyTypes?.length
      );

      if (invalidCas.length > 0 && hasValidCa) {
        for (var i = cf.items.length - 1; i >= 0; i--) {
          const ca = cf.items[i];
          if (
            ca.title.trim() === '' &&
            ca.frequencyTypes.filter((ft) => ft.value.trim() === '').length ==
              ca.frequencyTypes.length
          ) {
            cf.items.splice(i, 1);
          }
        }
      }
    });
  };

  const clearProtectionInconsistentValues = (values) => {
    Object.keys(values.protectionSpecification).map((key) => {
      const ps = values.protectionSpecification[key];
      const hasValidCa =
        ps.items.filter((ca) => ca.title.trim() !== '').length > 0;
      const invalidCas = ps.items.filter((ca) => ca.title.trim() === '');

      if (invalidCas.length > 0 && hasValidCa) {
        for (var i = ps.items.length - 1; i >= 0; i--) {
          const ca = ps.items[i];
          if (ca.title.trim() === '') {
            ps.items.splice(i, 1);
          }
        }
      }
    });
  };

  // Change Complete Method...
  const onCompleteButtonClick = async (
    {
      saveAndComplete = true,
      submitSignedContract = false,
      isOverwritten,
      isCityTemplate,
      isTemplate,
      isDuplicate = false,
    } = {},
    callback = () => {}
  ) => {
    try {
      setBlockAutoSave(true);
      setReady(false);

      try {
        if (values.uid && tabId) {
          await isTabIdValid(values.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e);

        callback();
        setBlockAutoSave(false);
        logSentry(e, values);
        return;
      }

      if (values.status === 2 && !isTemplate && !isDuplicate) {
        openProposalPDF();
        callback();
        setBlockAutoSave(false);
        return;
      }

      setIsLoading(true);
      const newValues = JSON.parse(JSON.stringify(values));

      const hasCleaningSpecs = !!sessionStorage.getItem(
        'sessionStorageCleaningSpecification'
      );

      const cleanSpecObject = JSON.parse(
        sessionStorage.getItem('sessionStorageCleaningSpecification')
      );

      if (hasCleaningSpecs && !!cleanSpecObject) {
        newValues.cleaningFrequency = cleanSpecObject;
      }

      clearCleaningInconsistentValues(newValues);
      clearProtectionInconsistentValues(newValues);

      if (!isDuplicate) {
        const errors =
          isCityTemplate || isTemplate
            ? validateSaveTemplate(newValues)
            : validateForm(newValues);
        const hasErrors =
          errors.required.length > 0 || errors.incorrect.length > 0;
        setErrors(hasErrors ? errors : {});
        if (hasErrors) {
          setIsLoading(false);
          callback();
          setBlockAutoSave(false);
          return;
        }
      }

      if (newValues.uid && saveAndComplete) {
        try {
          const blankPdfResult = await checkIsGeneratingPDF(newValues.uid);
        } catch (e) {
          const generating = e.data['isGenerating'];
          if (generating) {
            setWarningModal({
              message:
                'Previous PDF Version still being generated. Please try again.',
              proceed: () => {
                setWarningModal(null);
              },
            });
            callback();
            setBlockAutoSave(false);
            setIsLoading(false);
            return;
          }
        }
      }

      clearTimeout(autoSaveTimeout);
      setAutoSaveTimeout(null);
      setExecuteAutoSave(null);

      // Cleaning Background Data
      if (newValues.frequencyService === 'monthly') {
        newValues.frequency = newValues.secondFrequency = {
          selecteds: [],
          timeWindow: null,
        };
        newValues.addFrequency = false;
        newValues.smartClean = false;
        newValues.disinfection = null;
        newValues.secondDisinfection = null;
      } else {
        newValues.cleaning = null;
        newValues.secondCleaning = null;
        newValues.timeWindow = null;
        newValues.secondTimeWindow = null;
        newValues.monthlyDisinfection = null;
        newValues.secondMonthlyDisinfection = null;
        newValues.monthlyAddFrequency = null;
      }

      if (newValues.uid && newValues.isTemplate) {
        newValues.uid = null;
      }
      newValues.sentStatus = isTemplate || isCityTemplate ? false : true;
      newValues.isTemplate = isTemplate;
      newValues.isCityTemplate = isCityTemplate;

      if (saveAndComplete || submitSignedContract) {
        newValues.generateBlankPDF = true;
      } else {
        newValues.generateBlankPDF = false;
      }

      //set TabId before save
      newValues.tabId = tabId;
      newValues.bidVersion = 2;
      newValues.isAutoSave = false;

      if (Object.entries(bidFullData).length > 0) {
        if (bidFullData.BidUniqueID) {
          newValues.BidUniqueID = bidFullData.BidUniqueID;
        }

        newValues.FlgSupply = bidFullData.FlgSupply;
        newValues.FlgSupplyCustomize = bidFullData.FlgSupplyCustomize;
        newValues.BidInformationSheetSupplys =
          bidFullData.BidInformationSheetSupplys;

        newValues.FlgReference = bidFullData.FlgReference;
        newValues.FlgReferenceCustomize = bidFullData.FlgReferenceCustomize;
        newValues.BidInformationSheetReferences =
          bidFullData.BidInformationSheetReferences;

        newValues.FlgProtectionDisinfectionPlan =
          bidFullData.FlgProtectionDisinfectionPlan;
      }

      newValues.tempBidUniqueID = newValues.BidUniqueID;
      newValues.tempUid = newValues.uid;

      if (newValues.status !== 2 && newValues.newReferenceName) {
        newValues.referenceName = newValues.newReferenceName;
      }

      if (isOverwritten && (isCityTemplate || isTemplate)) {
        newValues.tempBidUniqueID = values.newBidUniqueID;
      }
      if (!isOverwritten && (isCityTemplate || isTemplate)) {
        newValues.tempBidUniqueID = undefined;
        newValues.tempUid = undefined;
      }

      let templateObj = {};

      if (isTemplate) {
        templateObj = {
          physicalURL: null,
          isPhysicalSigned: null,
          signatureDate: null,
          status: null,
          referenceName: newValues.newReferenceName,
          pid: '',
        };
      }

      if (isTemplate || isCityTemplate) {
        templateObj = {
          ...templateObj,
          sentStatus: false,
        };
      }

      const result = await doPost(
        {
          ...newValues,
          BidUniqueID: newValues.tempBidUniqueID,
          uid: newValues.tempUid,
          ...templateObj,
          isDuplicate,
          isOverwritten,
        },
        () => {},
        false
      );
      if (!isTemplate && !isCityTemplate) {
        setAllValues(newValues);
      }

      setHadChanges(false);

      setIsLoading(false);

      if (result) {
        setTempData(null);
        if (saveAndComplete) {
          setShowConfirmationModal(true);
        } else if (submitSignedContract) {
          props.history.push(
            `proposal/physicalContract?formUniqueId=${result.id}&franchiseId=${franchiseId}&physicalContract=true`
          );
        }

        let successMessage = '';

        if (newValues.isCityTemplate) {
          if (isOverwritten) {
            successMessage = 'City Template was overwritten successfully';
          } else {
            successMessage =
              'City Template saved successfully! This template is now available for use and can be edited from the Templates Menu.';
          }
        } else if (newValues.isTemplate) {
          if (isOverwritten) {
            successMessage = 'My Template was overwritten successfully';
          } else {
            successMessage =
              'Template saved successfully! This template is now available for use and can be edited from the Templates Menu.';
          }
        } else if (newValues.sentStatus) {
          successMessage = 'Your sent proposal was saved successfully';
        } else {
          successMessage = 'Your draft was saved successfully';
        }

        toast.info(successMessage);

        callback();

        setTimeout(() => {
          setBlockAutoSave(false);
          setAutoSaveTimeout(1);
          setReady(true);
        }, 0);
      } else {
        callback();
      }
    } catch (e) {
      console.log('e', e);
      setError(e);
    }
  };

  // Method to save Draft
  const onSaveDraft = React.useCallback(
    async (isAutoSave = false, tempData = null) => {
      let v = tempData || values;
      if (Object.entries(bidFullData).length > 0) {
        // This probably is some defaults
        v.FlgSupply = bidFullData.FlgSupply;
        v.FlgSupplyCustomize = bidFullData.FlgSupplyCustomize;
        v.BidInformationSheetSupplys = bidFullData.BidInformationSheetSupplys;

        v.FlgReference = bidFullData.FlgReference;
        v.FlgReferenceCustomize = bidFullData.FlgReferenceCustomize;
        v.BidInformationSheetReferences =
          bidFullData.BidInformationSheetReferences;
        v.FlgProtectionDisinfectionPlan =
          bidFullData.FlgProtectionDisinfectionPlan;
      }

      // Check tab valid
      try {
        if (values.uid && tabId) {
          await isTabIdValid(values.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e, isAutoSave);
        logSentry(e, values);
        return { error: true };
      }

      if (!isAutoSave) {
        setIsLoading(true);
      } else {
        if (showConnectionWarning) {
          setShowConnectionWarning(false);
          const message =
            bidFullData.FirstSaveFromImport == '1'
              ? 'Auto-Save Enabled'
              : 'Draft Auto-Save Enabled';
          toast.info(message);
        }
      }

      //set TabId before save
      v.tabId = tabId;
      v.userId = userId;
      if (v.uid) {
        v.BidUniqueID = v.uid;
      }

      if (!isAutoSave) {
        v.isAutoSave = false;
      }

      setCompleteButtonEnabled(false);
      let response;
      if (isAutoSave) {
        response = await autoSave(v, userId, isAutoSave);
      } else {
        response = await updateBid({ values: v, userId, showToastr: true });
      }
      setExecuteAutoSave(null);
      setCompleteButtonEnabled(true);
      setHadChanges(false);
      setReady(false);
      if (response.data) {
        if (!isAutoSave) {
          setFieldValue('uid', response.data.id);
          setBidData(response.data as any);
        } else {
          setFieldValue('isAutoSave', true);
        }
      } else if (response.error) {
        toast.error(
          isAutoSave
            ? 'Auto-save failed. Please reload the page.'
            : response.error
        );
      }

      if (!isAutoSave) {
        setIsLoading(false);
      }

      setTimeout(() => {
        setReady(true);
      }, 0);

      return response;
    },
    [
      values,
      isAlive,
      isTabIdValid,
      setWarningModal,
      setIsLoading,
      saveDraft,
      setHadChanges,
      setFieldValue,
      setBidData,
      bidFullData,
      setShowConnectionWarning,
      showConnectionWarning,
    ]
  );

  const onSaveTemplate = async (
    isCityTemplate = false,
    isSaveAs = false,
    isOverwritten = false
  ) => {
    setBlockAutoSave(true);
    setExecuteAutoSave(null);
    const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';
    try {
      const newValues =
        isCityTemplate && !isCityUser
          ? JSON.parse(JSON.stringify(values))
          : values;

      if (isCityTemplate && !isCityUser) {
        newValues.uid = null;
      }

      try {
        if (newValues.uid && tabId) {
          await isTabIdValid(newValues.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e);
        logSentry(e, newValues);
        return;
      }

      const proceed = async () => {
        try {
          setWarningModal(null);
          setIsLoading(true);

          //set TabId before save
          newValues.tabId = tabId;
          onCompleteButtonClick({
            saveAndComplete: false,
            isOverwritten,
            isCityTemplate,
            isTemplate: true,
          });
        } catch (err) {
          setGenericError(err);
        }
      };
      proceed();
    } catch (e) {
      setGenericError(e);
    }
  };

  const onDuplicateProposal = async () => {
    try {
      if (values.uid && tabId) {
        await isTabIdValid(values.uid, tabId);
      } else {
        await isAlive();
      }
    } catch (e) {
      handleError(e);
      logSentry(e, values);
      return;
    }

    const proceed = () => {
      setIsLoading(true);
      // setTimeout(() => {
      //   props.history.push('/loading');
      // }, 0);

      // setTimeout(() => {
      //   props.history.push({
      //     pathname: '/bid-info-form',
      //     search: `?userId=${userId}&franchiseId=${franchiseId}`,
      //     state: {
      //       formValues: {
      //         ...values,
      //         pid: null,
      //         uid: null,
      //         id: null,
      //         bidInfoId: null,
      //         physicalURL: null,
      //         isPhysicalSigned: null,
      //         signatureDate: null,
      //         isTemplate: false,
      //         isCityTemplate: false,
      //         sentStatus: false,
      //         terms: null,
      //         status: null,
      //         externalId: null,
      //       },
      //     },
      //   });
      // }, 0);
      onCompleteButtonClick({
        isCityTemplate: false,
        isOverwritten: false,
        isTemplate: false,
        isDuplicate: true,
        submitSignedContract: false,
        saveAndComplete: false,
      });
    };

    if (values.status == 2) {
      if (values.bidVersion == 1) {
        setWarningModal({
          title: 'WARNING: Removing Bid Overrides',
          message: (
            <>
              This proposal was created using previous version of Anago
              CleanSource®. The duplicate of this proposal will clear any Bid
              Overrides entered previously.
            </>
          ),
          dismiss: () => setWarningModal(null),
          proceed: proceed,
        });
      } else {
        proceed();
      }
    } else {
      if (values.bidVersion == 1) {
        setWarningModal({
          title: 'WARNING: Removing Bid Overrides',
          message: (
            <>
              This proposal was created using previous version of Anago
              CleanSource®. The duplicate of this proposal will clear any Bid
              Overrides entered previously.
              <br />
              <br />
              Proceeding will lose any unsaved changes. Are you sure you will
              wish to proceed?"
            </>
          ),
          dismiss: () => setWarningModal(null),
          proceed: proceed,
        });
      } else {
        setWarningModal({
          message:
            'Proceeding will lose any unsaved changes. Are you sure you will wish to proceed?',
          dismiss: () => setWarningModal(null),
          proceed: proceed,
        });
      }
    }
  };

  const hasProtectionCleaningSpecs =
    Object.keys(values.cleaningFrequency).filter(
      (k) =>
        values?.cleaningFrequency?.[k]?.items?.filter((item) =>
          item?.title?.includes?.('Anago Protection+ Disinfection')
        )?.length > 0
    )?.length > 0;

  /// if is new

  React.useEffect(() => {
    if (bidFullData?.Status !== 2 && franchiseData) {
      if (bidFullData['HasAlreadyGenerateDocForThisDocument']) {
        getSignNowStatus(bidFullData['BidUniqueID'])
          .then(({ data: result }) => {
            if (result['Status'] != 'true') {
              setWarningModal({
                message:
                  "You're trying to edit an ongoing BID, doing so will invalidate any progress the client may have done.",
                dismissText: 'Cancel',
                confirmText: 'Confirm',
                proceed: () =>
                  setInvalidDocument(bidFullData['BidUniqueID']).then(
                    (result) => {
                      window.location.reload();
                    }
                  ),
                dismiss: () => {
                  window.close();
                },
                isVisible: true,
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [bidFullData, franchiseData]);

  React.useEffect(() => {
    if (executeAutoSave && !blockAutoSave && values.status !== 2) {
      if (!values.pid) {
        setTimeout(() => {
          startAutoSaveTimeout();
        });
        return;
      }

      const save = async () => {
        // Check what I need to send here and change on autosave...

        // const hasTemp = !!tempData && Object.keys(tempData).length > 0;
        // let tValues = null;
        // if (hasTemp) {
        //   tValues = {
        //     ...values,
        //     uid: tempData.uid,
        //     bidInfoId: tempData.bidInfoId,
        //     originalBidUid: tempData.originalBidUid,
        //     isTemp: true,
        //     userId: userId,
        //     isAutoSave: true,
        //   };
        // } else if (values.uid && !!values.sentStatus) {
        //   tValues = {
        //     ...values,
        //     uid: null,
        //     bidInfoId: null,
        //     originalBidUid: values.uid,
        //     isTemp: true,
        //     userId: userId,
        //     isAutoSave: true,
        //   };
        // } else {
        //   tValues = { ...values, userId: userId, isAutoSave: true };
        // }

        // if (
        //   (hasTemp && !compareValues(tValues, tempData)) ||
        //   (!hasTemp && hadChanges)
        // ) {
        let response;
        if (!values.isTemplate && !values.isCityTemplate) {
          response = await onSaveDraft(true, { ...values });

          if (!response.error) {
            // console.error('saved draft! id:' + response.data.id);
            setTimeout(() => {
              setHadChanges(false);
            }, 0);
          } else {
            if (response.error !== true) {
              // toast.error('Error trying to Auto-Save');
              console.error('Error:' + response.error);

              if (response.status == '409') {
                setTempData(null);
                setAutoSaveTimeout(null);
                setExecuteAutoSave(null);
                clearTimeout(autoSaveTimeout);
                setBlockAutoSave(true);

                setWarningModal({
                  title: 'Warning!',
                  message:
                    'Autosave did not complete correctly. Please manually save this form to prevent loss of any data.',
                  proceed: () => setWarningModal(null),
                });
              }
            }
          }
        }
        // }
        setTimeout(() => {
          startAutoSaveTimeout();
        });
      };
      save();
    }
  }, [executeAutoSave]);

  const startAutoSaveTimeout = React.useCallback(() => {
    // console.error('starting auto-save timeout');
    setAutoSaveTimeout(
      setTimeout(() => {
        if (!blockAutoSave) {
          setExecuteAutoSave(Math.random());
        }
      }, 10 * 1000)
    );
  }, [hadChanges, blockAutoSave]);

  const initAutoSave = async () => {
    if (values.isTemplate) {
      return;
    }

    setShowEmptyPidWarning(false);
    try {
      if (values.uid && tabId) {
        await isTabIdValid(values.uid, tabId);
      } else {
        await isAlive();
      }
    } catch (e) {
      handleError(e, true);
      logSentry(e, values);
      if (!executeAutoSave) {
        setBlockAutoSave(false);
        setTimeout(() => {
          startAutoSaveTimeout();
        });
      }
      return;
    }

    setShowConnectionWarning(false);
    if (!executeAutoSave) {
      setBlockAutoSave(false);
      setTimeout(() => {
        startAutoSaveTimeout();
      });
      const message =
        bidFullData.FirstSaveFromImport == '1'
          ? 'Auto-Save Enabled'
          : 'Draft Auto-Save Enabled';
      toast.info(message);
    }
  };

  React.useEffect(() => {
    if (ready && !document.querySelector('.save-template-modal')) {
      if (values.status != 2 && (!hadChanges || showConnectionWarning)) {
        if (autoSaveTimeout == 1) {
          if (values.uid && !showConnectionWarning) {
            if (!values.pid) {
              setShowEmptyPidWarning(true);
            } else {
              setHadChanges(true);
              initAutoSave();
            }
          }
        } else if (autoSaveTimeout == null) {
          if (!values.pid && newForm) {
            setShowEmptyPidWarning(true);
          } else if (newForm && !showConnectionWarning) {
            initAutoSave();
          }
        }
      }
    }
  }, [values]);

  React.useEffect(() => {
    return () => {
      setAutoSaveTimeout(null);
      setExecuteAutoSave(null);
      clearTimeout(autoSaveTimeout);
    };
  }, []);

  /// end

  React.useEffect(() => {
    (async () => {
      try {
        const result = await verifyServerUnavailable(userId);
        if (!result || result.error) {
          setBlockingModal({
            message:
              'Server under maintenance at this time, please try again later',
          });
        } else {
          setBlockingModal(null);
        }
      } catch (e) {
        const errorResponse = e && e.response ? e.response : e;
        if (errorResponse.status == '503') {
          setBlockingModal({
            message:
              'Server under maintenance at this time, please try again later',
          });
        }
      }
    })();
  }, []);

  const refreshIntegration = async (externalId) => {
    try {
      const userData = await axiosInstance.get(
        `BidSheet/GetByUniqueWaterStreetID/${externalId}`
      );

      const apiValues = parseWaterStreet(userData.data.WaterStreet);
      toast.info('Refreshed with success!');

      setValues({ ...values, ...apiValues });
    } catch (response) {
      setWarningModal({
        message: 'Error when try to refresh the Bid info',
        proceed: () => {
          setWarningModal(null);
        },
      });
    }
  };

  return (
    <FormProvider
      needsSaving={needsSaving}
      setNeedsSaving={setNeedsSaving}
      formValues={values}
      setFormValues={setAllValues}
      onChange={setFieldValue}
      showWarningModal={setWarningModal}
      showConfirmationModal={setShowConfirmationModal}
      showDeleteConfirmationModal={showDeleteConfirmationModal}
    >
      <div className="inspection-root-div bid-form-root">
        <div className="inspection-content-screen bid-form-content-screen">
          {isLoading && <LoadingPage />}

          <ActionAlertModal
            message="This form has already been signed, and cannot be changed."
            isVisible={showAlreadySignedModal && !shouldShowSignNowModal}
            onConfirm={() => {
              setShowAlreadySignedModal(false);
            }}
          />

          <ActionAlertModal
            message="The current proposal is expired. To reactive this proposal a valid proposal date is required, would you like to update the proposal date for today?"
            isVisible={showExpiredBid}
            onConfirm={() => {
              setFieldValue('bidDate', new Date());
              setShowExpiredBid(false);
            }}
            onDismiss={() => {
              setShowExpiredBid(false);
              setFieldValue('status', 2);
              //read only
            }}
          />

          <ActionAlertModal
            title="Uh oh, it appears that the link you attempted to access is no longer supported. Due to recent updates, certain links have been deprecated."
            message="We understand the inconvenience this may cause and sincerely apologize for disrupting your experience. If you need assistance, please contact AFI Support at Anagocleaning.com"
            isVisible={showNoBidUniqueID}
            titleStyle={{
              fontSize: '20px',
              textTransform: 'unset',
            }}
            onConfirm={() => {
              window.location.href = ANAGO_CLEANSOURCE_DEV_LOGIN_URL.replace(
                'login',
                'home'
              );
            }}
          />

          {blockingModal && (
            <BlockingOverlay>
              <BlockingPopupModal
                message={blockingModal.message}
                title={blockingModal.title}
              />
            </BlockingOverlay>
          )}

          {shouldShowSignNowModal && (
            <BlockingOverlay>
              <SignNowScreen
                userId={userId}
                status={userStatus}
                setStatus={setUserStatus}
                setLoading={setIsLoading}
              />
            </BlockingOverlay>
          )}
          <Header
            showConnectionWarning={showConnectionWarning}
            showEmptyPidWarning={showEmptyPidWarning}
            setShowEmptyPidWarning={setShowEmptyPidWarning}
            showAutoPidTooltip={showAutoPidTooltip}
            onExit={() => setShowExit(true)}
            openSearch={onOpenSearch}
            setIsLoading={setIsLoading}
            setNeedsSaving={setNeedsSaving}
            title="Bid Information Sheet"
            searchButtonLabel="Open Bid forms"
          />
          <ClientInfo refreshIntegration={refreshIntegration} />
          <div className="header inline">
            <h1 style={{ margin: 0, padding: '20px' }}>Bid Details</h1>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button
                disabled={values.status === 2}
                className="button rounded"
                style={{
                  gap: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '20px',
                }}
                onClick={() => {
                  setShowLoadTemplateModal(true);
                }}
              >
                <FontAwesomeIcon icon={faDownload} />
                Load template
              </button>
              {values.referenceName && (
                <div
                  className="bid-input-content"
                  style={{ width: '300px', padding: '10px 20px 10px 0' }}
                >
                  <div className="bid-input-label">Template Name</div>
                  <input
                    value={values.referenceName}
                    onChange={(e) =>
                      setFieldValue('referenceName', e.target.value)
                    }
                    maxLength={24}
                    className="input-header input-bid validate-pid"
                    disabled
                  />
                </div>
              )}
            </div>
          </div>
          <AccountInfo />
          <AdditionalInformation />
          <SectionGroup>
            <ServiceArea
              title="SERVICE AREAS"
              sectionId="serviceAreasSection"
            />
          </SectionGroup>
          <RoomInventory />
          <FloorTypeTotals />
          <RestroomFixtures />
          <ProductionRates />
          <CleaningSpecification />
          <DailyManHours />

          <CancellationClause
            onClickDownloadBlankPdf={onClickDownloadBlankPdf}
          />
          <SectionGroup title="ADDITIONAL PRODUCTS & SERVICES">
            <Supplies
              onChange={(newBidFullData) => {
                setFieldValue('flgSupply', newBidFullData.FlgSupply);
                setFieldValue(
                  'flgSupplyCustomize',
                  newBidFullData.FlgSupplyCustomize
                );
                setFieldValue(
                  'bidInformationSheetSupplys',
                  newBidFullData.BidInformationSheetSupplys
                );
                setBidFullData(newBidFullData);
              }}
              franchiseId={franchiseId}
              title="SUPPLIES"
              formUniqueId={uniqueId}
              sectionId="suppliesSection"
              bidFullData={bidFullData}
              canEdit={userData && userData.IsSuppliesAddEdit}
              isExpandedByDefault={bidFullData.Status !== 2}
            />
            <References
              onChange={(newBidFullData) => {
                setFieldValue('flgReference', newBidFullData.FlgReference);
                setFieldValue(
                  'flgReferenceCustomize',
                  newBidFullData.FlgReferenceCustomize
                );
                setFieldValue(
                  'bidInformationSheetReferences',
                  newBidFullData.BidInformationSheetReferences
                );
                setBidFullData(newBidFullData);
              }}
              franchiseId={franchiseId}
              title="GOOGLE REVIEWS, REFERENCES, & TESTIMONIALS"
              sectionId="referencesSection"
              formUniqueId={uniqueId}
              bidFullData={bidFullData}
              canEdit={userData && userData.IsReferenceAddEdit}
              isExpandedByDefault={bidFullData.Status !== 2}
            />
            <DayPorter
              title="DAY PORTER SERVICES"
              sectionId="dayPorterSection"
            />
            <ProtectionDisinfectionPlans
              bidFullData={bidFullData}
              sectionId="protectionDisinfectionSection"
              onChangeFlag={(newBidFullData) => {
                setFieldValue(
                  'flgProtectionDisinfectionPlan',
                  newBidFullData.FlgProtectionDisinfectionPlan
                );
                setBidFullData(newBidFullData);
              }}
              title="ANAGO PROTECTION+ DISINFECTION® PLAN(S)"
              disabled={
                (values.smartClean &&
                  values.addFrequency &&
                  values.frequencyService == 'weekly') ||
                hasProtectionCleaningSpecs
              }
              shouldKeepHidden={values.smartClean || hasProtectionCleaningSpecs}
              canOpenSection={() => {
                if (!values.smartClean && !hasProtectionCleaningSpecs) {
                  return true;
                }
                if (values.smartClean && values.addFrequency) {
                  setWarningModal({
                    message:
                      'Protection+ is not available when SmartClean is selected.',
                    proceed: () => {
                      setWarningModal(null);
                    },
                  });
                  return false;
                }
                if (hasProtectionCleaningSpecs) {
                  setWarningModal({
                    message:
                      'Protection+ is not available when has protection specification inside cleaning specification.',
                    proceed: () => {
                      setWarningModal(null);
                    },
                  });
                  return false;
                }
              }}
            />
            <AdditionalQuotes
              title="SPECIALTY SERVICE QUOTE(S)"
              sectionId="additionalQuotesSection"
            />
          </SectionGroup>

          <BidInformation />
          <Footer
            onCompleteButtonClick={onCompleteButtonClick}
            completeButtonEnabled={completeButtonEnabled}
            saveDraft={onSaveDraft}
            saveTemplate={onSaveTemplate}
            showDuplicateButton={!franchiseData?.IsOptInCleanSuite}
            duplicateProposal={onDuplicateProposal}
            isUserRole={isUserRole}
            showContractButton={
              !!bidFullData?.BidLink &&
              values.status === 1 &&
              values.sentStatus &&
              franchiseData &&
              franchiseData?.IsOptInCleanSuite
            }
          />
        </div>
      </div>

      {warningModal && (
        <ActionAlertModal
          title={warningModal.title}
          style={warningModal.style}
          message={warningModal.message}
          onConfirm={warningModal.proceed}
          onDismiss={warningModal.dismiss}
          isDismissPrimary={warningModal.isDismissPrimary}
          dismissText={warningModal.dismissText}
          confirmText={warningModal.confirmText}
          isVisible={true}
        />
      )}
      <ActionAlertModal
        title="Exit without saving?"
        message="Remember, that if you exit without saving, you will lose all progress."
        onConfirm={() => {
          setShowExit(true);

          if (isWebView()) {
            props.history.push('/close-form');
          } else {
            document.location.href = getBidURL();
          }
        }}
        onDismiss={() => setShowExit(false)}
        isVisible={showExit}
      />

      <ActionAlertModal
        message={errors}
        onConfirm={() => setErrors({})}
        isVisible={Object.keys(errors).length > 0}
      />

      {showMenu && (
        <GenericModal
          isOpen={showMenu}
          showHeader={false}
          clickOutsideToClose={false}
          fullscreen={false}
          height="710px"
          width="600px"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <BidFormMenuScreen
            {...props}
            showBackground={false}
            franchiseName={franchiseData?.AnagoOfCityName}
            {...showMenu}
            doneAction={(state) => {
              setShowMenu(null);
              setIsLoading(state);
              if (!state) {
                setAutoSaveTimeout(1);
                setExecuteAutoSave(Math.random());
                setReady(true);
              }
            }}
          />
        </GenericModal>
      )}

      {showPdf && (
        <GenericModal
          isOpen={showPdf}
          showHeader={false}
          clickOutsideToClose={false}
          fullscreen={false}
          height="100%"
          width="100%"
          // maxWidth="1200px"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <RenderPdf
            onClickClose={() => setShowPdf(false)}
            pdfName={`${values.pid}_AnagoAgreement_${values.uid}`}
            type={11}
            pdfUrl={
              bidFullData.IsPhysicalSigned ? bidFullData.PhysicalURL : undefined
            }
            uid={values.uid}
          />
        </GenericModal>
      )}

      {showConfirmation && <ConfirmationModal {...confirmationProps} />}
      {showLoadTemplateModal && (
        <LoadTemplateModal
          location={props.location}
          franchiseName={franchiseData?.AnagoOfCityName}
          doneAction={() => {
            setShowLoadTemplateModal(false);
          }}
          navigateToForm={navigateToForm}
        />
      )}
    </FormProvider>
  );
};

const mapForm = {
  mapPropsToValues: () => ({
    ...defaultValues,
    quotes: [],
  }),
  handleSubmit: () => '',
  enableReinitialize: true,
};

const FormEnhanced = withFormik<any, IBidInfoForm>(mapForm)(
  withRouter(BidForm)
);

export { FormEnhanced as BidForm };
