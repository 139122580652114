import React, { memo, useEffect } from 'react';

import { ContentInput, Select, useFormProps } from 'core/components';

import {
  lengthTermOptions,
  cancellationNoticesOptions,
  cancellationReasonOptions,
  curePeriodOptions,
  cancellationClauseValues,
} from 'modules/shared/bidDefaultValues';

import './styles.scss';
import { FormikProps, withFormik } from 'formik';
import { IBidCancellationClause } from 'modules/BidInfoForm/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

type IProps = FormikProps<IBidCancellationClause>;

const CancellationClause = memo(
  ({ values, setFieldValue, onClickDownloadBlankPdf }: IProps) => {
    const { formValues, onChange, showWarningModal } = useFormProps();
    const [lengthOfTerms, setLengthOfTerms] = React.useState([]);
    const [cancellationReason, setCancellationReason] = React.useState([]);
    const [cancellationNotice, setCancellationNotice] = React.useState([]);
    const [curePeriod, setCurePeriod] = React.useState([]);
    const [changedSomething, setChangedSomething] = React.useState(false);

    const [showModificationAlert, setShowModificationAlert] =
      React.useState(false);

    const [haveModifiedTerms, setHaveModifiedTerms] = React.useState(false);

    useEffect(() => {
      if (formValues.terms) {
        if (
          formValues.terms.find((i) => i.systemReference === 'Term6').modified
        ) {
          setHaveModifiedTerms(true);
        } else {
          setHaveModifiedTerms(false);
        }
      }
    }, [formValues.terms]);

    useEffect(() => {
      if (
        !changedSomething &&
        ((formValues &&
          formValues.lengthTerm !== cancellationClauseValues.lengthTerm) ||
          formValues.cancellationNotice !==
            cancellationClauseValues.cancellationNotice ||
          formValues.cancellationReason !==
            cancellationClauseValues.cancellationReason ||
          formValues.curePeriod !== cancellationClauseValues.curePeriod)
      ) {
        setShowModificationAlert(formValues.sentStatus);
      } else {
        setShowModificationAlert(false);
      }
    }, [formValues, changedSomething]);

    const filterLengthOfTerms = () => {
      return lengthTermOptions;
    };

    const filterCancellationNotice = () => {
      if (formValues.lengthTerm == 'Month to Month') {
        return ['30 Days', '45 Days'];
      } else {
        return cancellationNoticesOptions;
      }
    };

    const filterCancellationReason = () => {
      if (formValues.lengthTerm == 'Month to Month') {
        return ['Any Reason'];
      } else {
        return cancellationReasonOptions;
      }
    };

    const filterCurePeriod = () => {
      if (
        formValues.cancellationReason == 'Any Reason' ||
        formValues.lengthTerm == 'Month to Month'
      ) {
        return ['No Cure'];
      } else {
        return curePeriodOptions;
      }
    };

    React.useEffect(() => {
      setLengthOfTerms(filterLengthOfTerms());
      setCancellationNotice(filterCancellationNotice());
      setCancellationReason(filterCancellationReason());
      setCurePeriod(filterCurePeriod());
    }, [
      values.lengthTermOptions,
      values.cancellationNoticeOptions,
      values.cancellationReasonOptions,
      values.curePeriodOptions,
      formValues.lengthTerm,
      formValues.cancellationReason,
      formValues.cancellationNotice,
      formValues.curePeriod,
    ]);

    // React.useEffect(() => {
    //   if (!cancellationNotice.includes(formValues.cancellationNotice)) {
    //     onChange('cancellationNotice', null);
    //   }

    //   if (!cancellationReason.includes(formValues.cancellationReason)) {
    //     if (cancellationReason.length === 1) {
    //       onChange('cancellationReason', cancellationReason[0]);
    //     } else {
    //       onChange('cancellationReason', null);
    //     }
    //   }

    //   if (!curePeriod.includes(formValues.curePeriod)) {
    //     if (curePeriod.length === 1) {
    //       onChange('curePeriod', curePeriod[0]);
    //     } else {
    //       onChange('curePeriod', null);
    //     }
    //   }
    // }, [lengthOfTerms, cancellationReason, cancellationNotice, curePeriod]);

    const onChangeWrapper =
      (preChanges: () => void = () => {}) =>
      (name: string, value: string) => {
        const proceed = () => {
          if (preChanges) {
            preChanges();
          }
          onChange(name, value);
          onChange('terms6Changed', true);
          setChangedSomething(true);
          showWarningModal(null);
        };

        if (haveModifiedTerms && !changedSomething) {
          showWarningModal({
            title: 'WARNING',
            message: (
              <div style={{ position: 'relative' }}>
                <div
                  onClick={() => {
                    onClickDownloadBlankPdf &&
                      onClickDownloadBlankPdf(formValues.uid);
                  }}
                  style={{
                    position: 'absolute',
                    top: '-75px',
                    right: '0px',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload} size="1x" color="#333" />
                    <span style={{ paddingTop: 10, fontSize: '14px' }}>
                      View PDF
                    </span>
                  </div>
                </div>
                This paragraph contains modifications from the default template.
                Changing this field will clear any modifications entered
                previously. This action cannot be undone and these modifications
                will be lost. Before continuing we advise to review the current
                version.
              </div>
            ),
            proceed: proceed,
            dismiss: () => showWarningModal(null),
          });
          return;
        }

        proceed();
      };

    return (
      <div className="cancellation-clause-root">
        <div className="cancellation-clause-content">
          {haveModifiedTerms && !changedSomething ? (
            <ContentInput
              width="100%"
              padding="10px 0px"
              className="warning-block"
            >
              <h3 className="cancellation-clause-title">
                Paragraph VI - Cancellation Clause
              </h3>
              <span className="message">
                The content of this paragraph has been manually edited and
                contains modifications from the default template.
              </span>
            </ContentInput>
          ) : (
            <ContentInput width="100%" padding="10px 0px">
              <h3 className="cancellation-clause-title">
                Paragraph VI - Cancellation Clause
              </h3>
            </ContentInput>
          )}

          <div className="cancellation-row">
            <div className="cancellation-column">
              <div className="cancellation-label">1 - Length Of Term</div>
              <Select
                name="lengthTerm"
                placeholder="Please Select an Option"
                className={`validate-lengthTerm`}
                value={formValues.lengthTerm}
                onChange={(name, value) => {
                  onChangeWrapper(() => {
                    if (
                      (formValues.lengthTerm == 'Month to Month' &&
                        value !== 'Month to Month') ||
                      (formValues.lengthTerm != 'Month to Month' &&
                        value == 'Month to Month')
                    ) {
                      onChange('cancellationReason', null);
                      onChange('cancellationNotice', null);
                      onChange('curePeriod', null);
                    }
                  })(name, value);
                }}
                items={lengthOfTerms}
                width="350px"
                disableInput
                disabled={formValues.status === 2}
              />
            </div>
            <div className="cancellation-column">
              <div className="cancellation-label">3 - Cancellation Reason</div>
              <Select
                name="cancellationReason"
                placeholder="Please Select an Option"
                className={`validate-cancellationReason`}
                value={formValues.cancellationReason}
                onChange={(name, value) => {
                  onChangeWrapper(() => {
                    onChange('curePeriod', null);
                  })(name, value);
                }}
                items={cancellationReason}
                width="350px"
                disableInput
                disabled={formValues.status === 2}
              />
            </div>
          </div>

          <div className="cancellation-row">
            <div className="cancellation-column">
              <div className="cancellation-label">2 - Cancellation Notice</div>
              <Select
                name="cancellationNotice"
                placeholder="Please Select an Option"
                className={`validate-cancellationNotice`}
                value={formValues.cancellationNotice}
                onChange={(name, value) => {
                  onChangeWrapper(() => {
                    onChange('cancellationReason', null);
                    onChange('curePeriod', null);
                  })(name, value);
                }}
                items={cancellationNotice}
                width="350px"
                disableInput
                disabled={formValues.status === 2}
              />
            </div>
            <div className="cancellation-column">
              <div className="cancellation-label">4 - Cure Period</div>
              <Select
                name="curePeriod"
                placeholder="Please Select an Option"
                className={`validate-curePeriod`}
                value={formValues.curePeriod}
                onChange={onChangeWrapper()}
                items={curePeriod}
                width="350px"
                disableInput
                disabled={formValues.status === 2}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const mapForm = {
  mapPropsToValues: () => ({
    lengthTermOptions,
    cancellationNoticesOptions,
    cancellationReasonOptions,
    curePeriodOptions,
  }),
  handleSubmit: () => '',
};

const EnhancedCancellationClause = withFormik<any, IBidCancellationClause>(
  mapForm
)(CancellationClause);

export { EnhancedCancellationClause as CancellationClause };
