import React, { useEffect, useMemo } from 'react';
import { getQueryString } from 'core/utils';
import page4ManPic from '../../../../assets/page-4-man.png';

import {
  Layout,
  ResponsiveLayout,
  ResponsiveBoxes,
  PricingBoxes,
  ResponsiveIpadLayout,
  RoundSubSection,
  SubSectionHeader,
  GraySection,
} from '../../../../components/layout';
import {
  Paragraph,
  Input,
  InputBlock,
  PeoplePicture,
} from '../../../../components/atoms';
import { RadioButton, ContentInput } from 'core/components';
import PhysicalContract from './PhysicalContract';
import {
  SectionTitle,
  SubSectionTitle,
  H3SemiBold,
  H3Bold,
  HeaderBase,
  LabelPlus,
  H3BoldItalic,
  DisclaimerParagraph,
} from '../../../../styles';
import {
  InvoiceTitle,
  InvoiceImageHolder,
  InvoiceButtons,
  Field,
  Block,
  BlockItem,
  EcoInvoiceOption,
} from './styles';
import ecoInvoiceImage from '../../../../assets/eco-invoice.png';
import {
  toTitleCase,
  formatNumberByMask,
  isNumber,
  formatPrice,
} from 'core/utils';
import { moneyMaskParams } from 'core/utils/masks';
import Debit from './Debit';
import TermsAndConditions from './TermsAndConditions';
import Signature from './Signature';
import { simplifyConsecutiveDays } from '../../../../utils';

import {
  MonthlyServiceQuoteOptions,
  DeclineMonthlyServiceOption,
} from '../../../../../../core/components/ProtectionMonthlyServiceQuote';

import { optionsPlans } from 'modules/shared/bidDefaultValues';

import autoCapitalizeFunction from './autocapitalize';

const getFieldValue = (value) => (!!value ? value : '');
const getDateValue = (value) => {
  if (!value) {
    return '';
  }

  var options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return value.toLocaleDateString('en-US', options); // Saturday, September 17, 2016
};

const joinFieldValue = (joinChar = ',', ...values) =>
  values.reduce((acc, i) => {
    if (acc != '' && i != '') {
      acc += `${joinChar} `;
    }
    acc += i.trim();
    return acc;
  }, '');

const renderAdditionalQuotes = (
  frequency: any,
  active: boolean,
  isHeader: boolean = false,
  biggerThan768: boolean = false,
  salesTax: boolean = false
) => {
  const bgColor = '#216eab';
  const color = '#fff';
  return (
    <ResponsiveLayout
      padding="15px"
      paddingLeft="20px"
      paddingRight="20px"
      margin="0"
      width="100%"
      style={{
        backgroundColor: bgColor,
        border: !active ? '1px solid gray' : '1px solid #216eab',
        borderRadius: '5px',
        color: color,
      }}
    >
      <ContentInput paddingTop="0">
        <HeaderBase
          fontSize="17px"
          padding="5px"
          textAlign="left"
          color={color}
          data-cy="service"
        >
          {frequency.value}
        </HeaderBase>
      </ContentInput>
      <hr
        style={{
          width: '100%',
          borderColor: 'white',
          marginTop: '-5px',
        }}
      />
      <ContentInput paddingTop="0">
        <H3Bold maxFontSize="14px" padding="5px" textAlign="left" color={color}>
          Price{' '}
          <small>
            {salesTax && <sup>†</sup>}
            <sup>‡</sup>
          </small>
        </H3Bold>
        <HeaderBase
          fontSize="17px"
          padding="5px"
          textAlign="left"
          color={color}
          data-cy="service-price"
        >
          {!frequency.uponRequest &&
            !frequency.unit &&
            `${frequency.oneTimePrice}${
              frequency.perHour
                ? ' /hr.'
                : frequency.perMonth
                ? ' /mo.'
                : ' /srvc.'
            }`}
          {frequency.uponRequest && 'Upon Request'}
          {frequency.unit &&
            `${frequency.unitPrice} per ${frequency.unitLabel}`}
          {` ${frequency.priceInfo || ''}`}
        </HeaderBase>
      </ContentInput>
      {/* {frequency.serviceDescription && (
        <ContentInput paddingTop="0">
          <H3Bold
            maxFontSize="14px"
            padding="0"
            textAlign="left"
            color={color}
            paddingLeft={biggerThan768 ? '10px' : '5px'}
          >
            Service Description
          </H3Bold>
          <HeaderBase
            fontSize="17px"
            padding="0"
            textAlign="left"
            color={color}
            paddingLeft={biggerThan768 ? '10px' : '5px'}
          >
            {frequency.serviceDescription}
          </HeaderBase>
        </ContentInput>
      )} */}
    </ResponsiveLayout>
  );
};

const renderAdditionalQuotes768 = (
  frequency: any,
  active: boolean,
  isHeader: boolean = false,
  biggerThan768: boolean = true,
  salesTax: boolean = false
) => {
  const bgColor = '#216eab';
  const color = '#fff';
  return (
    <ResponsiveIpadLayout
      width="100%"
      paddingLeft="0"
      marginBottom="0"
      paddingTop={'5px'}
    >
      <PricingBoxes
        padding="0 10px 0 0 "
        minWidth="140px"
        width="80%"
        boxSizing="border-box"
      >
        {isHeader && (
          <H3Bold
            color="#000"
            maxFontSize="15px"
            padding="5px"
            textAlign="center"
            data-cy="service-types-title"
          >
            Service Types
          </H3Bold>
        )}
        <InputBlock
          backgroundColor={bgColor}
          color={color}
          data-cy="service-types-input"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: frequency.priceInfo ? '75px' : '55px',
          }}
        >
          <span
            style={{
              alignSelf: 'center',
              width: '90%',
            }}
          >
            {frequency.value}
          </span>
        </InputBlock>
      </PricingBoxes>

      <PricingBoxes
        padding="0 0 0 10px"
        minWidth="200px"
        width="20%"
        boxSizing="border-box"
        marginBottom="20px"
      >
        {isHeader && (
          <H3Bold
            color="#000"
            maxFontSize="15px"
            padding="5px"
            textAlign="center"
            data-cy="service-price-title"
          >
            Price{' '}
            <small>
              {salesTax && <sup>†</sup>}
              <sup>‡</sup>
            </small>
          </H3Bold>
        )}
        <InputBlock
          data-cy="service-price-input"
          backgroundColor={bgColor}
          color={color}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: frequency.priceInfo ? '75px' : '55px',
          }}
        >
          <span>
            {frequency.uponRequest
              ? 'Upon Request'
              : frequency.unit
              ? `${frequency.unitPrice} per ${frequency.unitLabel}`
              : `${frequency.oneTimePrice}${
                  frequency.perHour
                    ? ' /hr.'
                    : frequency.perMonth
                    ? ' /mo.'
                    : ' /srvc.'
                }`}
          </span>
          {frequency.priceInfo && <span>{frequency.priceInfo}</span>}
        </InputBlock>
      </PricingBoxes>
    </ResponsiveIpadLayout>
  );
};

const Page4 = (props, ref) => {
  const isPhysicalContract =
    getQueryString(window.location.href, 'physicalContract') == 'true';
  const [equalsOrBiggerThan768, setEqualsOrBiggerThan768] =
    React.useState(false);
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [biggerThan768, setBiggerThan768] = React.useState(false);
  const [minorThan768, setMinorThan768] = React.useState(false);
  const [minorThan450, setMinorThan450] = React.useState(false);

  const setBreakpoints = () => {
    setEqualsOrBiggerThan768(window.innerWidth >= 768);
    setBiggerThan796(window.innerWidth >= 796);
    setBiggerThan768(window.innerWidth >= 769);
    setMinorThan768(window.innerWidth < 768);
    setMinorThan450(window.innerWidth < 450);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  const renderAdditionalQuotesFunction = equalsOrBiggerThan768
    ? renderAdditionalQuotes768
    : renderAdditionalQuotes;

  const isSmartCleanSet = props.data && props.data['SmartClean'];

  const getDaysPorterDays = () => {
    let ret = '';
    if (values['DaysPotterMonday']) {
      ret += 'Monday, ';
    }
    if (values['DaysPotterTuesday']) {
      ret += 'Tuesday, ';
    }
    if (values['DaysPotterWednesday']) {
      ret += 'Wednesday, ';
    }
    if (values['DaysPotterThursday']) {
      ret += 'Thursday, ';
    }
    if (values['DaysPotterFriday']) {
      ret += 'Friday, ';
    }
    if (values['DaysPotterSaturday']) {
      ret += 'Saturday, ';
    }
    if (values['DaysPotterSunday']) {
      ret += 'Sunday, ';
    }
    return ret.substr(0, ret.length - 2);
  };

  const { selectedValues, data, parsedData, franchiseData, selectedItem } =
    props;
  const values = data || {};
  const parsedValue = parsedData || {};
  const franchiseValues = franchiseData || {};
  const [discount, setDiscount] = React.useState();
  const debitRef = React.useRef();
  const signatureRef = React.useRef();
  const reset = () => undefined;
  const hasAdditionalQuotes =
    values['AdditionalQuotes'] &&
    values['AdditionalQuotes'].length > 0 &&
    values['AdditionalQuotes'].filter(
      (i) =>
        i['Total'] > 0 ||
        i['TotalOverride'] > 0 ||
        i['UponRequest'] ||
        i['UnitPrice'] > 0
    ).length > 0 &&
    selectedValues.page3.additionalQuotes &&
    selectedValues.page3.additionalQuotes.length > 0;

  React.useEffect(() => {
    if (
      /\(iPhone|\(iPod|\(iPad|\(Macintosh/i.test(
        navigator.userAgent || navigator.vendor || window.opera
      )
    ) {
      autoCapitalizeFunction();
      // alert(JSON.stringify(navigator.userAgent));
    }
  }, [discount]);

  React.useImperativeHandle(ref, () => {
    return {
      reset,
      getData: () => {
        const debidData = debitRef.current ? debitRef.current.getData() : {};
        const signatureData = signatureRef.current
          ? signatureRef.current.getData()
          : {};

        return {
          discount,
          ...debidData,
          ...signatureData,
          ownerHasEcoInvoice: values['OwnerHasEcoInvoice'],
        };
      },
    };
  });

  const declinedMonthlyOption =
    props.selectedValues.page1.selectedFrequency == -1 ||
    props.selectedValues.page2.selectedFrequency == -1;

  useEffect(() => {
    if (discount) {
      if (
        declinedMonthlyOption &&
        !hasAdditionalQuotes &&
        (parsedValue.includeInPrice ||
          (!isNumber(parsedValue.porterOverride) &&
            !parsedValue.monthlyPorterPrice) ||
          !selectedValues.page3.dayPorter)
      ) {
        setDiscount(false);
      }
    }
  }, [
    declinedMonthlyOption,
    hasAdditionalQuotes,
    parsedValue.includeInPrice,
    parsedValue.porterOverride,
    parsedValue.monthlyPorterPrice,
    selectedValues.page3.dayPorter,
  ]);

  let daysServiced1 = values['DaysCleanedListText']
    ? values['DaysCleanedListText']
        .split(', ')
        .map((item) => item.substring(0, 3))
    : [];
  daysServiced1 = simplifyConsecutiveDays(daysServiced1);

  let daysServiced2 = values['SecondDaysCleanedListText']
    ? values['SecondDaysCleanedListText']
        .split(', ')
        .map((item) => item.substring(0, 3))
    : [];
  daysServiced2 = simplifyConsecutiveDays(daysServiced2);

  let daysServicedSmartClean = values['SelectedDaysCleanedListText']
    ? values['SelectedDaysCleanedListText']
        .split(', ')
        .map((item) => item.substring(0, 3))
    : [];
  daysServicedSmartClean = simplifyConsecutiveDays(daysServicedSmartClean);

  const frequencyOption = useMemo(() => {
    const hasProtectionPackages =
      parsedData &&
      (parsedData.protectionPackages.length > 0 ||
        parsedData.secondProtectionPackages.length > 0) &&
      !parsedData.smartClean;

    const services = [
      parsedData &&
        (parsedData.costSupplies ||
          (parsedData.includeInPrice &&
            (isNumber(parsedData.porterOverride) ||
              parsedData.monthlyPorterPrice > 0))) && {
          label: 'Custom Cleaning Service:',
          value: 'Included',
        },
      parsedData &&
        parsedData.includeInPrice &&
        (isNumber(parsedData.porterOverride) ||
          parsedData.monthlyPorterPrice > 0) && {
          label: 'Day Porter Included',
          value: formatPrice(
            isNumber(parsedData.porterOverride)
              ? parsedData.porterOverride
              : parsedData.monthlyPorterPrice
          ),
        },
      parsedData &&
        parsedData.costSupplies && {
          label: 'Supplies Included',
          value: formatPrice(parsedData.costSupplies),
        },
    ].filter((i) => !!i);

    if (isSmartCleanSet) {
      return {
        title:
          values['TitleQuote1'] ||
          `Quote 1: ${
            values['SmartCleanDaysCleaned'] || '0'
          }x Weekly SmartClean Service`,
        frequency: `${values['SmartCleanDaysCleaned'] || '0'}x Weekly`,
        daysServiced: values['SelectedDaysCleanedListCompactText'],
        timeWindow: values['TimeOfDay'],
        services: services,
        monthlyPrice: `$${formatNumberByMask(
          (
            (values['SmartCleanBidAmount']
              ? values['SmartCleanBidAmount']
              : 0) + (values['OwnerHasEcoInvoice'] ? 5 : 0)
          ).toString(),
          {
            ...moneyMaskParams,
            thousandsSeparatorSymbol: ',',
          }
        )}`,
      };
    }

    if (
      selectedValues.page1.selectedFrequency ||
      selectedValues.page2.selectedFrequency
    ) {
      let monthlyValue;

      if (hasProtectionPackages) {
        monthlyValue = parsedData.secondCalculatedPrice;
      } else {
        monthlyValue = `$${formatNumberByMask(
          (
            (values['SecondBidAmountOverride']
              ? values['SecondBidAmountOverride']
              : values['SecondBidAmount']
              ? values['SecondBidAmount']
              : 0) + (values['OwnerHasEcoInvoice'] ? 5 : 0)
          ).toString(),
          { ...moneyMaskParams, thousandsSeparatorSymbol: ',' }
        )}`;
      }

      if (values.IsMonthlyCleaning) {
        return {
          title:
            values['TitleQuote2'] ||
            `Quote 2: ${values['SecondTimesPerMonth'] || '0'}x Monthly Service`,
          frequency: `${values['SecondTimesPerMonth']}x Monthly`,
          daysServiced: 'MONTHLY',
          timeWindow: values['SecondTimeOfDay'],
          services: services,
          monthlyPrice: monthlyValue,
        };
      }

      return {
        title:
          values['TitleQuote2'] ||
          `Quote 2: ${values['SecondDaysCleaned'] || '0'}x Weekly Service`,
        frequency: `${values['SecondDaysCleaned'] || '0'}x Weekly`,
        daysServiced: values['SecondDaysCleanedListTextCompactText'],
        timeWindow: values['SecondTimeOfDay'],
        services: services,
        monthlyPrice: monthlyValue,
      };
    } else {
      let monthlyValue;

      if (hasProtectionPackages) {
        monthlyValue = parsedData.calculatedPrice;
      } else {
        monthlyValue = `$${formatNumberByMask(
          (
            (values['BidAmountOverride']
              ? values['BidAmountOverride']
              : values['BidAmount']
              ? values['BidAmount']
              : 0) + (values['OwnerHasEcoInvoice'] ? 5 : 0)
          ).toString(),
          {
            ...moneyMaskParams,
            thousandsSeparatorSymbol: ',',
          }
        )}`;
      }

      return {
        title:
          values['TitleQuote1'] ||
          `${
            !values.IsMonthlyCleaning
              ? `Quote 1: ${values.DaysCleaned || '0'}x Weekly Service`
              : `Quote 1: ${values.TimesPerMonth}x Monthly Service`
          }`,
        frequency: `${
          !values.IsMonthlyCleaning
            ? `${values.DaysCleaned || '0'}x Weekly`
            : `${values.TimesPerMonth}x Monthly`
        }`,
        daysServiced: !values.IsMonthlyCleaning
          ? values['DaysCleanedListTextCompactText']
          : 'MONTHLY',
        timeWindow: values['TimeOfDay'],
        services: services,
        monthlyPrice: monthlyValue,
      };
    }
  }, [
    selectedValues,
    parsedData,
    props.selectedValues.page2.selectedProtection,
    selectedValues.page1.selectedFrequency,
    selectedValues.page2.selectedFrequency,
  ]);

  const declinedProtectionOption =
    parsedData &&
    parsedData.protectionPackages &&
    props &&
    props.selectedValues &&
    props.selectedValues.page2 &&
    props.selectedValues.page2.selectedProtection !== undefined &&
    props.selectedValues.page2.selectedProtection !== null &&
    props.selectedValues.page2.selectedProtection == -1;

  const hasDisinfection = selectedValues.page1.selectedFrequency
    ? values['SecondFrequencyDisinfection']
    : values['IsMonthlyCleaning']
    ? values['MonthlyFrequencyDisinfection']
    : values['FirstFrequencyDisinfection'];

  const hasProtectionPackages =
    parsedData &&
    (parsedData.protectionPackages.length > 0 ||
      parsedData.secondProtectionPackages.length > 0) &&
    !parsedData.smartClean;

  const hasMonthlyFrequency =
    parsedData && parsedData.frequencyService === 'monthly';

  const declinedSpecialtyServices =
    !selectedValues.page3.additionalQuotes ||
    (selectedValues.page3.additionalQuotes.length == 0 &&
      !selectedValues.page3.dayPorter);

  const phoneType = ['Home', 'Cell', 'Work', 'Fax', 'Other'];

  const clientPhone = values['AddressedToWalkThru']
    ? values['WalkThruPhone']
    : values['DecisionMakerPhone'];
  const clientPhoneExt = values['AddressedToWalkThru']
    ? values['WalkThruPhoneExtension']
    : values['DecisionMakerPhoneExtension'];
  const clientPhoneType = values['AddressedToWalkThru']
    ? values['WalkThruPhoneType']
    : values['DecisionMakerPhoneType'];
  const clientPhoneParsed = clientPhone
    ? `${
        clientPhoneType
          ? phoneType[parseInt(clientPhoneType) - 1].slice(0, 1) + '. '
          : ''
      }${clientPhone}${clientPhoneExt ? ' ext ' + clientPhoneExt : ''}`
    : '';

  const accountsPhoneParsed = `${
    selectedValues.page4.accountsPhoneType
      ? selectedValues.page4.accountsPhoneType.slice(0, 1) + '. '
      : ''
  }${selectedValues.page4.accountsPhone}${
    selectedValues.page4.accountsPhoneExtension
      ? ' ext ' + selectedValues.page4.accountsPhoneExtension
      : ''
  }`;

  const emegercyPhoneParsed = `${
    selectedValues.page4.emergencyPhoneType
      ? selectedValues.page4.emergencyPhoneType.slice(0, 1) + '. '
      : ''
  }${selectedValues.page4.emergencyPhone}${
    selectedValues.page4.emergencyPhoneExtension
      ? ' ext ' + selectedValues.page4.emergencyPhoneExtension
      : ''
  }`;

  return (
    <Layout margin="auto" width="100%" backgroundColor="#ededed">
      <Layout
        margin="auto"
        maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}
        width="100%"
      >
        <GraySection width="auto" margin="0" padding="20px 20px 150px 20px">
          <RoundSubSection
            marginBottom="0"
            paddingBottom="25px"
            width="100%"
            backgroundColor="#fff"
            position="relative"
          >
            <SubSectionHeader
              alignItems={biggerThan768 ? 'start' : 'center'}
              backgroundColor="#216eab"
              color="#fff"
              padding={
                equalsOrBiggerThan768 ? '15px 0 15px 0' : '20px 0 20px 0'
              }
            >
              <SectionTitle
                maxFontSize="20px"
                padding="0 20px 0 20px"
                color="#fff"
                margin="0 !important"
                data-cy="summary-title"
              >
                Summary
              </SectionTitle>
            </SubSectionHeader>

            <Layout
              width="100%"
              backgroundColor="#fff"
              maxHeight={equalsOrBiggerThan768 ? '600px' : 'auto'}
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
            >
              <ContentInput
                padding="0"
                margin="0"
                width={
                  equalsOrBiggerThan768 && !values['ExistentClient']
                    ? '50%'
                    : '100%'
                }
                style={{
                  marginBottom: '0 !important',
                  paddingBottom: values['ExistentClient'] ? '65px' : '0',
                }}
              >
                <SubSectionTitle
                  maxFontSize="22px"
                  padding="20px"
                  paddingBottom="10px"
                  paddingLeft="0"
                  textAlign="left"
                  marginBottom="0"
                  data-cy="client-title"
                >
                  Client
                </SubSectionTitle>
                <Paragraph padding="0 20px" margin="0" data-cy="client-address">
                  {values['AddressedToWalkThru']
                    ? getFieldValue(values['ContactName'])
                    : getFieldValue(values['DecisionMaker'])}{' '}
                  <br />
                  {getFieldValue(values['BusinessName'])} <br />
                  {getFieldValue(values['Address'])} <br />
                  {getFieldValue(values['Suite'])}
                  {getFieldValue(values['Suite']) ? <br /> : null}
                  {joinFieldValue(
                    ',',
                    getFieldValue(values['City']),
                    `${getFieldValue(values['State'])} ${getFieldValue(
                      values['Zip']
                    )}`
                  )}
                  <br />
                  {clientPhoneParsed}
                  <br />
                  {values['AddressedToWalkThru']
                    ? getFieldValue(values['EmailAddress'])
                    : getFieldValue(values['DecisionMakerEmail'])}
                </Paragraph>
                <div
                  style={{
                    display: values['ExistentClient'] ? 'none' : 'block',
                  }}
                >
                  <SubSectionTitle
                    maxFontSize="22px"
                    padding="20px"
                    paddingBottom="10px"
                    marginBottom="0"
                    paddingLeft="0"
                    textAlign="left"
                    data-cy="account-payable-title"
                  >
                    Accounts Payable Information
                  </SubSectionTitle>
                  <Paragraph
                    padding="0 20px"
                    margin="0"
                    data-cy="account-payable-address"
                  >
                    {getFieldValue(selectedValues.page4.accountsName)} <br />
                    {getFieldValue(values['BusinessName'])} <br />
                    {getFieldValue(selectedValues.page4.billingAddress1)} <br />
                    {getFieldValue(selectedValues.page4.billingSuite)}
                    {getFieldValue(selectedValues.page4.billingSuite) ? (
                      <br />
                    ) : null}
                    {joinFieldValue(
                      ',',
                      getFieldValue(selectedValues.page4.billingCity),
                      `${getFieldValue(
                        selectedValues.page4.billingState
                      )} ${getFieldValue(
                        selectedValues.page4.billingPostalCode
                      )}`
                    )}
                    <br />
                    {accountsPhoneParsed} <br />
                    {getFieldValue(selectedValues.page4.accountsEmail)}
                  </Paragraph>
                  {!values['IsSS'] &&
                    selectedValues.page4.serviceCommencementDate && (
                      <div>
                        <SubSectionTitle
                          maxFontSize="22px"
                          padding="20px"
                          paddingBottom="10px"
                          marginBottom="0"
                          paddingLeft="0"
                          textAlign="left"
                          data-cy="service-commencement-date-title"
                        >
                          Service Commencement Date
                        </SubSectionTitle>
                        <Paragraph
                          data-cy="service-commencement-date"
                          padding="0 20px"
                          margin="0"
                          paddingBottom={'0'}
                        >
                          {getDateValue(
                            selectedValues.page4.serviceCommencementDate
                          )}
                        </Paragraph>
                      </div>
                    )}
                </div>
              </ContentInput>
              {!values['ExistentClient'] && (
                <ContentInput
                  padding="0"
                  margin="0"
                  width={equalsOrBiggerThan768 ? '50%' : '100%'}
                >
                  <SubSectionTitle
                    maxFontSize="22px"
                    padding="20px"
                    paddingBottom="10px"
                    marginBottom="0"
                    paddingLeft="0"
                    textAlign="left"
                    data-cy="emergency-contact-title"
                  >
                    Emergency Contact
                  </SubSectionTitle>
                  <Paragraph
                    padding="0 20px"
                    margin="0"
                    data-cy="emergency-contact"
                  >
                    {`${getFieldValue(
                      selectedValues.page4.emergencyFirstName
                    )} ${getFieldValue(
                      selectedValues.page4.emergencyLastName
                    )}`}
                    <br />
                    {emegercyPhoneParsed}
                    <br />
                    {getFieldValue(selectedValues.page4.emergencyEmail)}
                  </Paragraph>

                  {selectedValues.page4.taxFacilityExempt && (
                    <>
                      <SubSectionTitle
                        maxFontSize="22px"
                        padding="20px"
                        paddingBottom="10px"
                        marginBottom="0"
                        paddingLeft="0"
                        textAlign="left"
                        data-cy="tax-exemption-title"
                      >
                        Tax Exemption
                      </SubSectionTitle>
                      <Paragraph
                        padding="0 20px"
                        margin="0"
                        data-cy="tax-exemption"
                      >
                        {joinFieldValue(
                          ' -',
                          selectedValues.page4.taxFacilityExempt ? 'Yes' : '',
                          getFieldValue(selectedValues.page4.taxExemptionNumber)
                        )}
                      </Paragraph>
                    </>
                  )}
                </ContentInput>
              )}
            </Layout>
            <PeoplePicture
              src={page4ManPic}
              style={{
                margin: 'auto',
                marginTop: '20px',
                position: equalsOrBiggerThan768 ? 'absolute' : 'relative',
                right: equalsOrBiggerThan768 ? '120px' : '0px',
                bottom: 0,
                maxWidth: '210px',
              }}
            />
          </RoundSubSection>

          {(hasAdditionalQuotes ||
            ((values['CostOfDayPorter'] > 0 ||
              isNumber(values['DayPorterPriceOverride'])) &&
              selectedValues.page3.dayPorter)) && (
            <RoundSubSection
              width="100%"
              marginTop="30px !important"
              paddingBottom="25px"
              backgroundColor="white"
            >
              <SubSectionHeader
                backgroundColor="#216eab"
                color="#fff"
                padding={
                  equalsOrBiggerThan768 ? '15px 0 15px 0' : '20px 0 20px 0'
                }
                alignItems={biggerThan768 ? 'start' : 'center'}
              >
                <SectionTitle
                  maxFontSize="20px"
                  color="#fff"
                  padding="0 20px 0 20px"
                  margin="0 !important"
                >
                  Specialty Service(s) Selected
                </SectionTitle>
              </SubSectionHeader>
              <Layout
                alignItems="start"
                backgroundColor="#fff"
                padding="20px"
                paddingTop="0"
                width="100%"
                boxSizing="border-box"
              >
                {values['AdditionalQuotes'] &&
                  values['AdditionalQuotes']
                    .filter(
                      (i) =>
                        i['Total'] > 0 ||
                        i['TotalOverride'] > 0 ||
                        i['UponRequest'] ||
                        i['UnitPrice'] > 0
                    )
                    .filter(
                      (e) =>
                        selectedValues.page3.additionalQuotes &&
                        selectedValues.page3.additionalQuotes.includes(
                          e['BidInformationAdditionalQuotesId']
                        )
                    )
                    .map((item, index, arr) => {
                      return (
                        <Layout
                          flexDirection="row"
                          marginRight="0"
                          marginLeft={'0'}
                          width={'100%'}
                          padding={
                            equalsOrBiggerThan768 || index === 0
                              ? '0'
                              : '20px 0 0 0'
                          }
                          paddingBottom={
                            equalsOrBiggerThan768
                              ? index == arr.length - 1 &&
                                !selectedValues.page3.dayPorter
                                ? '20px !important'
                                : !franchiseData.SalesTax
                                ? index == arr.length - 1 &&
                                  (!selectedValues.page3.dayPorter ||
                                    (!values['CostOfDayPorter'] &&
                                      !isNumber(
                                        values['DayPorterPriceOverride']
                                      )))
                                  ? '20px !important'
                                  : '0px'
                                : '0px'
                              : '20px'
                          }
                          backgroundColor={
                            equalsOrBiggerThan768 ? '#fff' : null
                          }
                        >
                          {renderAdditionalQuotesFunction(
                            {
                              value: item['QuotesType'],
                              oneTimePrice: `$${formatNumberByMask(
                                (item['TotalOverride']
                                  ? item['TotalOverride']
                                  : item['Total']
                                ).toString(),
                                {
                                  ...moneyMaskParams,
                                  thousandsSeparatorSymbol: ',',
                                }
                              )}`,
                              perHour: item['PerHour'],
                              perMonth: item['PerMonth'],
                              serviceDescription: item['ServiceDescription'],
                              uponRequest: item['UponRequest'],
                              unit: item['Unit'],
                              unitPrice: formatPrice(item['UnitPrice']),
                              unitLabel: item['UnitLabel'],
                            },
                            false,
                            index === 0 || !equalsOrBiggerThan768,
                            equalsOrBiggerThan768,
                            franchiseData && franchiseData.SalesTax
                          )}
                        </Layout>
                      );
                    })}
                {selectedValues.page3.dayPorter &&
                  (values['CostOfDayPorter'] > 0 ||
                    isNumber(values['DayPorterPriceOverride'])) && (
                    <Layout
                      flexDirection="row"
                      marginRight="0"
                      marginLeft={'0'}
                      width={'100%'}
                      padding={equalsOrBiggerThan768 ? '0' : '20px 0 0 0'}
                      paddingBottom="20px !important"
                      backgroundColor={equalsOrBiggerThan768 ? 'white' : null}
                    >
                      {renderAdditionalQuotesFunction(
                        {
                          value: `Day Porter Services ${getDaysPorterDays()} for ${
                            values['DayPorterHoursPerDay']
                          } hours per day.`,
                          oneTimePrice: `$${formatNumberByMask(
                            (isNumber(values['DayPorterPriceOverride'])
                              ? values['DayPorterPriceOverride']
                              : values['CostOfDayPorter']
                            ).toString(),
                            {
                              ...moneyMaskParams,
                              thousandsSeparatorSymbol: ',',
                            }
                          )}`,
                          priceInfo: parsedData.includeInPrice
                            ? '(included)'
                            : null,
                          perHour: false,
                          perMonth: true,
                          uponRequest: false,
                          unit: false,
                        },
                        false,
                        !equalsOrBiggerThan768 ||
                          !selectedValues.page3.additionalQuotes ||
                          selectedValues.page3.additionalQuotes.length == 0,
                        equalsOrBiggerThan768,
                        franchiseData && franchiseData.SalesTax
                      )}
                    </Layout>
                  )}

                {franchiseValues.SalesTax && true && (
                  <Layout
                    width="100%"
                    backgroundColor={equalsOrBiggerThan768 ? '#fff' : null}
                    marginLeft={'0'}
                    marginTop={equalsOrBiggerThan768 ? '-20px' : '0px'}
                    paddingRight="8px"
                  >
                    <DisclaimerParagraph
                      italic
                      style={{
                        textAlign: equalsOrBiggerThan768 ? 'right' : 'center',
                      }}
                      width="100%"
                      marginTop="5px"
                      marginBottom="5px"
                      fontSize="11px"
                    >
                      <span
                        style={{
                          paddingRight: '0px',
                        }}
                      >
                        <small className="tm">†</small> Plus Applicable Sales
                        Tax.
                      </span>
                    </DisclaimerParagraph>
                  </Layout>
                )}

                <DisclaimerParagraph italic textAlign="justify !important">
                  {values['ServiceFooter'] && <small className="tm">‡</small>}
                  {/* Client is responsible to remove
                  and replace furniture and items from floor being serviced. A
                  minimum charge of $150 for the first hour plus an additional
                  $25.090 per hour thereafter will apply if Anago’s
                  representatives must move items. */}
                  {values['ServiceFooter']}
                </DisclaimerParagraph>

                {!values['IsSS'] &&
                  values['AdditionalQuotes'] &&
                  values['AdditionalQuotes']
                    .filter(
                      (e) =>
                        selectedValues.page3.additionalQuotes &&
                        selectedValues.page3.additionalQuotes.includes(
                          e['BidInformationAdditionalQuotesId']
                        )
                    )
                    .filter(
                      (i) =>
                        i['QuotesType'].indexOf('Comprehensive Detail Clean') >=
                        0
                    ).length > 0 && (
                    <DisclaimerParagraph
                      italic
                      textAlign="justify !important"
                      marginTop={equalsOrBiggerThan768 ? '0px' : null}
                    >
                      <small className="tm">*</small> Initial Cleans must be
                      scheduled for completion within the first 30 days of the
                      agreement. A Company Representative will contact you to
                      determine cleaning date.
                    </DisclaimerParagraph>
                  )}
              </Layout>
            </RoundSubSection>
          )}

          {declinedMonthlyOption && (
            <RoundSubSection
              width="100%"
              marginTop="30px !important"
              backgroundColor="white"
            >
              <SubSectionHeader
                backgroundColor="#216eab"
                color="#fff"
                padding={
                  equalsOrBiggerThan768 ? '15px 0 15px 0' : '20px 0 20px 0'
                }
                alignItems={'center'}
              >
                <SectionTitle
                  maxFontSize="20px"
                  color="#fff"
                  padding="0 20px 0 20px"
                  margin="0 !important"
                >
                  Monthly Service Quote(s)
                </SectionTitle>
              </SubSectionHeader>

              {equalsOrBiggerThan768 ? (
                <Layout
                  alignItems="flex-start"
                  color="#fff"
                  padding="20px"
                  width="100%"
                  marginLeft="0"
                  boxSizing="border-box"
                  backgroundColor="white"
                  paddingBottom="0 !important"
                  marginBottom="20px"
                  marginTop="0"
                >
                  <Layout
                    flexDirection="row"
                    marginRight="0"
                    marginLeft="0"
                    marginBottom={'5px'}
                    width="100%"
                  >
                    <Block>
                      <BlockItem>
                        <Field
                          color="white"
                          style={{
                            height: hasDisinfection ? '67.5px' : 'auto',
                            height: discount ? '70px' : 'auto',
                            backgroundColor: '#216eab',
                            paddingLeft: '20px',
                            alignItems: 'flex-start',
                          }}
                          data-cy="decline-option"
                        >
                          I Decline a Monthly Service option.
                        </Field>
                      </BlockItem>
                    </Block>
                  </Layout>
                </Layout>
              ) : (
                <ResponsiveLayout
                  padding="15px"
                  paddingLeft="20px"
                  paddingRight="20px"
                  margin="20px 20px 0 20px;"
                  width="auto"
                  style={{
                    backgroundColor: '#216eab',
                    color: 'white',
                    border: '1px solid gray',
                    borderRadius: '5px',
                    marginBottom: '20px',
                  }}
                >
                  <ContentInput paddingTop="0">
                    <HeaderBase
                      fontSize="17px"
                      padding="5px"
                      textAlign="left"
                      color="white"
                      data-cy="decline-option"
                    >
                      I Decline a Monthly Service option.
                    </HeaderBase>
                  </ContentInput>
                </ResponsiveLayout>
              )}
            </RoundSubSection>
          )}

          {values['OwnerHasEcoInvoice'] &&
            (values['IsSS'] ||
              (!values['IsSS'] &&
                (!declinedMonthlyOption ||
                  hasAdditionalQuotes ||
                  ((values['CostOfDayPorter'] > 0 ||
                    isNumber(values['DayPorterPriceOverride'])) &&
                    selectedValues.page3.dayPorter)))) && (
              <RoundSubSection
                width="100%"
                backgroundColor="#fff"
                marginTop="30px !important"
              >
                <SubSectionHeader
                  backgroundColor="#F7A11D"
                  color="#fff"
                  padding={
                    equalsOrBiggerThan768 ? '15px 0 15px 0' : '20px 0 20px 0'
                  }
                  alignItems={biggerThan768 ? 'start' : 'center'}
                >
                  <SectionTitle
                    maxFontSize="20px"
                    color="#fff"
                    padding="0 20px 0 20px"
                    margin="0 !important"
                  >
                    {values['IsSS']
                      ? 'Specialty Service Pricing'
                      : declinedMonthlyOption
                      ? 'Payment Method'
                      : 'Monthly Service Pricing'}
                  </SectionTitle>
                </SubSectionHeader>

                {values['OwnerHasEcoInvoice'] &&
                  (!declinedMonthlyOption ||
                    hasAdditionalQuotes ||
                    (!parsedData.includeInPrice &&
                      (isNumber(parsedData.porterOverride) ||
                        parsedData.monthlyPorterPrice > 0) &&
                      selectedValues.page3.dayPorter)) && (
                    <Layout padding="20px" paddingBottom="0 !important">
                      <InvoiceImageHolder>
                        <img
                          src={ecoInvoiceImage}
                          width="80%"
                          style={{ maxWidth: '260px' }}
                        />
                      </InvoiceImageHolder>

                      {equalsOrBiggerThan768 &&
                        !values['IsSS'] &&
                        !declinedMonthlyOption && (
                          <InvoiceTitle textAlign="center">
                            <span>Receive a $5 Discount</span> on monthly
                            recurring service! <br />
                            Select <span>Eco-Invoice</span>&#8482; to go
                            paperless with automatic ACH debit.
                            <br />
                          </InvoiceTitle>
                        )}

                      {!equalsOrBiggerThan768 &&
                        !values['IsSS'] &&
                        !declinedMonthlyOption && (
                          <InvoiceTitle textAlign="center">
                            <span>Receive a $5 Discount</span> <br /> on monthly
                            recurring service!
                            <br />
                            Select <span>Eco-Invoice</span>&#8482; to go
                            paperless with automatic ACH debit.
                            <br />
                          </InvoiceTitle>
                        )}

                      {(values['IsSS'] || declinedMonthlyOption) && (
                        <InvoiceTitle textAlign="center">
                          Select <span>Eco-Invoice</span>&#8482; to go paperless
                          with automatic ACH debit.
                          <br />
                        </InvoiceTitle>
                      )}

                      <InvoiceButtons>
                        <RadioButton
                          value={discount}
                          onChange={(name, value) => {
                            setDiscount(value);
                          }}
                          containerStyle={
                            equalsOrBiggerThan768
                              ? {
                                  justifyContent: 'center',
                                }
                              : {}
                          }
                          contentStyle={
                            equalsOrBiggerThan768
                              ? {
                                  width: '70%',
                                  flexDirection: 'row',
                                  justifyContent: 'space-around',
                                }
                              : {}
                          }
                          name="discount"
                          label=""
                          options={[
                            {
                              value: true,
                              label: (
                                <EcoInvoiceOption data-cy="eco-invoice-option-yes">
                                  <strong>Yes</strong>
                                </EcoInvoiceOption>
                              ),
                            },
                            {
                              value: false,
                              label:
                                values['IsSS'] || declinedMonthlyOption ? (
                                  <div
                                    style={{
                                      width: '160px',
                                      textAlign: 'left',
                                    }}
                                    data-cy="eco-invoice-option-no"
                                  >
                                    No
                                  </div>
                                ) : (
                                  <span data-cy="eco-invoice-option-no-discount">
                                    No, I don't like discounts
                                  </span>
                                ),
                            },
                          ]}
                          optionLabel="label"
                          optionValue="value"
                        />
                      </InvoiceButtons>
                      {!values['IsSS'] && !declinedMonthlyOption && (
                        <Paragraph fontSize="14px" marginTop="0">
                          Get your invoice emailed right to your inbox instead
                          of in the mail. We’ll apply your $5 discount and bill
                          you automatically each month, and you’ll save time,
                          money, and trees. 
                        </Paragraph>
                      )}
                      {(values['IsSS'] || declinedMonthlyOption) && (
                        <Paragraph fontSize="14px" marginTop="0">
                          Get your invoice emailed right to your inbox instead
                          of in the mail. We’ll bill you automatically, and
                          you’ll save time, money, and trees.
                        </Paragraph>
                      )}
                    </Layout>
                  )}

                {!equalsOrBiggerThan768 &&
                  !hasProtectionPackages &&
                  !values['IsSS'] &&
                  !declinedMonthlyOption && (
                    <ContentInput padding="0" width="100%">
                      <ResponsiveLayout
                        padding="10px"
                        paddingLeft="20px"
                        paddingRight="20px"
                        width="auto"
                        margin="20px"
                        style={{
                          backgroundColor: '#216eab',
                          color: 'white',
                          border: '1px solid gray',
                          borderRadius: '5px',
                        }}
                      >
                        <ContentInput paddingTop="0">
                          <HeaderBase
                            fontSize="15px"
                            padding="5px"
                            textAlign="left"
                            style={{ fontWeight: 'bold' }}
                            color="white"
                            data-cy="frequency-title"
                          >
                            {frequencyOption.title}
                          </HeaderBase>
                          {hasDisinfection && (
                            <H3BoldItalic
                              color="#F7A11D"
                              marginTop="-5px"
                              padding="5px"
                              textAlign="center"
                              style={{ display: 'flex' }}
                            >
                              & Protection{' '}
                              <LabelPlus style={{ paddingLeft: '2px' }}>
                                {' '}
                                +
                              </LabelPlus>{' '}
                              Disinfection&#174;
                            </H3BoldItalic>
                          )}
                        </ContentInput>
                        <hr
                          style={{
                            width: '100%',
                            borderColor: 'white',
                            marginTop: '-5px',
                          }}
                        />
                        <ContentInput
                          paddingTop="0"
                          flex
                          flexDirection="row"
                          justify="flex-start"
                          style={{ lineHeight: '16px' }}
                        >
                          <H3Bold
                            maxFontSize="14px"
                            padding="5px"
                            textAlign="left"
                            color="white"
                            width="auto"
                            data-cy="frequency-header"
                            textAlign={biggerThan768 ? 'center' : 'left'}
                          >
                            Frequency:
                          </H3Bold>
                          <H3Bold
                            maxFontSize="14px"
                            padding="5px"
                            textAlign="left"
                            width="auto"
                            style={{ fontFamily: 'OpenSans-Regular' }}
                            color="white"
                            data-cy="frequency-input"
                          >
                            {frequencyOption.frequency}
                          </H3Bold>
                        </ContentInput>
                        <ContentInput
                          paddingTop="0"
                          flex
                          flexDirection="row"
                          justify="flex-start"
                          style={{ lineHeight: '16px' }}
                        >
                          <H3Bold
                            maxFontSize="14px"
                            padding="5px"
                            textAlign="left"
                            color="white"
                            width="auto"
                            data-cy="days-serviced-header"
                            textAlign={biggerThan768 ? 'center' : 'left'}
                          >
                            Days Serviced:
                          </H3Bold>
                          <H3Bold
                            maxFontSize="14px"
                            padding="5px"
                            textAlign="left"
                            width="auto"
                            data-cy="days-serviced-input"
                            style={{ fontFamily: 'OpenSans-Regular' }}
                            color="white"
                          >
                            {frequencyOption.daysServiced}
                          </H3Bold>
                        </ContentInput>
                        <ContentInput
                          paddingTop="0"
                          flex
                          flexDirection="row"
                          justify="flex-start"
                          style={{ lineHeight: '16px' }}
                        >
                          <H3Bold
                            maxFontSize="14px"
                            padding="5px"
                            textAlign="left"
                            color="white"
                            width="auto"
                            data-cy="time-window-header"
                            textAlign={biggerThan768 ? 'center' : 'left'}
                          >
                            Time Window:
                          </H3Bold>
                          <H3Bold
                            maxFontSize="14px"
                            padding="5px"
                            textAlign="left"
                            width="auto"
                            data-cy="time-window-input"
                            style={{ fontFamily: 'OpenSans-Regular' }}
                            color="white"
                          >
                            {frequencyOption.timeWindow}
                          </H3Bold>
                        </ContentInput>
                        {frequencyOption.services &&
                          frequencyOption.services.map((service, sIndex) => (
                            <ContentInput
                              flex
                              flexDirection="row"
                              justify="space-between"
                              alignItems="center"
                              style={{
                                lineHeight: '16px',
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                border: 'none',
                                padding: '0 5px',
                                marginBottom: '5px',
                              }}
                            >
                              <H3Bold
                                padding="5px"
                                textAlign="left"
                                color="#515151"
                                width="auto"
                                data-cy={`service-label-${sIndex}`}
                                textAlign={biggerThan768 ? 'center' : 'left'}
                                style={{
                                  fontFamily: 'OpenSans-Regular',
                                  position: 'relative',
                                  fontSize: '12.5px',
                                }}
                              >
                                {service.label}
                              </H3Bold>
                              <H3Bold
                                fontSize="12.5px"
                                padding="5px"
                                textAlign="left"
                                width="auto"
                                data-cy={`service-value-${sIndex}`}
                                style={{
                                  fontFamily: 'OpenSans-Regular',
                                  fontSize: '12.5px',
                                }}
                                color="#515151"
                              >
                                {service.value}
                              </H3Bold>
                            </ContentInput>
                          ))}
                        <ContentInput
                          paddingTop="10px"
                          flex
                          flexDirection="row"
                          justify="space-between"
                          style={{ lineHeight: '16px' }}
                        >
                          <H3Bold
                            maxFontSize="14px"
                            padding="5px"
                            textAlign="left"
                            color="white"
                            width="auto"
                            data-cy="price-header"
                            textAlign={biggerThan768 ? 'center' : 'left'}
                            style={{ position: 'relative' }}
                          >
                            Total Monthly Price:
                            {franchiseValues.SalesTax && <sup>†</sup>}
                          </H3Bold>
                          <HeaderBase
                            fontSize="15px"
                            padding="5px"
                            textAlign="left"
                            width="auto"
                            style={{
                              fontFamily: 'OpenSans-Bold',
                              textDecoration: discount
                                ? 'line-through'
                                : 'none',
                            }}
                            color="white"
                            data-cy="price-value"
                          >
                            {frequencyOption.monthlyPrice}
                          </HeaderBase>
                        </ContentInput>
                        {discount && (
                          <ContentInput
                            paddingTop="0px"
                            flex
                            flexDirection="row"
                            justify="space-between"
                            style={{ lineHeight: '16px' }}
                          >
                            <H3Bold
                              maxFontSize="14px"
                              padding="5px"
                              textAlign="left"
                              color="white"
                              width="auto"
                              textAlign={biggerThan768 ? 'center' : 'left'}
                              style={{ position: 'relative' }}
                            >
                              <span style={{ color: '#98ff98' }}>
                                {' '}
                                Eco-Invoice <sup>&#174;</sup>
                                {franchiseValues.SalesTax && <sup>†</sup>}
                              </span>
                            </H3Bold>
                            <HeaderBase
                              fontSize="15px"
                              padding="5px"
                              textAlign="left"
                              width="auto"
                              style={{
                                fontFamily: 'OpenSans-Bold',
                              }}
                              data-cy="price-value"
                              color="white"
                            >
                              <span
                                style={{
                                  paddingLeft: '0',
                                  color: '#98ff98',
                                }}
                              >
                                {biggerThan768 && <br />}
                                <strong>{`$${formatNumberByMask(
                                  (
                                    Number(
                                      frequencyOption.monthlyPrice
                                        .replace(',', '')
                                        .substr(1)
                                    ) - (values['OwnerHasEcoInvoice'] ? 5 : 0)
                                  )?.toFixed?.(2),
                                  {
                                    ...moneyMaskParams,
                                    thousandsSeparatorSymbol: ',',
                                  }
                                )}`}</strong>
                              </span>
                            </HeaderBase>
                          </ContentInput>
                        )}
                      </ResponsiveLayout>
                      {franchiseValues.SalesTax && !declinedMonthlyOption && (
                        <Paragraph
                          width={'100%'}
                          marginTop="-10px"
                          textAlign={'center'}
                          paddingRight="0"
                          color="#000"
                          fontSize="11px"
                        >
                          <i>
                            <small className="tm">†</small> Plus Applicable
                            Sales Tax.
                          </i>
                        </Paragraph>
                      )}
                    </ContentInput>
                  )}
                {!values['IsSS'] &&
                  !declinedMonthlyOption &&
                  (hasProtectionPackages ? (
                    !props.selectedValues.page2.selectedFrequency ? (
                      <MonthlyServiceQuoteOptions
                        isHtml
                        selected
                        biggerThan768={equalsOrBiggerThan768}
                        hasDesinfection={
                          hasMonthlyFrequency
                            ? parsedData.monthlyDisinfection
                            : parsedData.disinfection
                        }
                        readyOnly
                        plusApplicableTax={franchiseValues.SalesTax}
                        containerStyle={{
                          width: 'calc(100% - 40px)',
                          paddingBottom: '20px',
                        }}
                        style={{
                          width: '100%',
                          backgroundColor: !props.selectedValues.page2
                            .selectedFrequency
                            ? '#216EAB'
                            : '#e6e6e6',
                          borderRadius: '10px',
                        }}
                        color={
                          !props.selectedValues.page2.selectedFrequency
                            ? 'white'
                            : '#515151'
                        }
                        selectedProtection={
                          props.selectedValues.page2.selectedProtection
                        }
                        calculatedTotal={frequencyOption.monthlyPrice}
                        hasBeta={values['OwnerHasEcoInvoice']}
                        optionBeta={discount}
                        packageIncluded={parsedData.protectionIncludedInPrice}
                        items={[
                          {
                            title: `${
                              parsedData.smartClean ? 'SmartClean' : 'Quote 1'
                            }: ${
                              parsedData.quoteTitle ||
                              `${
                                hasMonthlyFrequency
                                  ? parsedData.cleaning
                                  : parsedData.smartClean
                                  ? parsedData.frequency.selecteds.length +
                                    ((parsedData.secondFrequency &&
                                      parsedData.secondFrequency.selecteds
                                        .length) ||
                                      0)
                                  : parsedData.frequency.selecteds.length
                              }x ${toTitleCase(parsedData.frequencyService)}`
                            }`,
                            frequency: `${
                              hasMonthlyFrequency
                                ? parsedData.cleaning
                                : parsedData.smartClean
                                ? parsedData.frequency.selecteds.length +
                                  ((parsedData.secondFrequency &&
                                    parsedData.secondFrequency.selecteds
                                      .length) ||
                                    0)
                                : parsedData.frequency.selecteds.length
                            }x ${toTitleCase(parsedData.frequencyService)}`,

                            hasDisinfection:
                              parsedData.protectionIncludedInPrice ||
                              !declinedProtectionOption,
                            daysServiced: hasMonthlyFrequency
                              ? 'MONTHLY'
                              : data['DaysCleanedListTextCompactText'],
                            timeWindow: hasMonthlyFrequency
                              ? parsedData.timeWindow
                              : parsedData.frequency.timeWindow,
                            services: [
                              {
                                service: 'Customized Cleaning Service',
                                value: parsedData.protectionIncludedInPrice
                                  ? 'Included'
                                  : formatPrice(
                                      parsedData.bidOverrideBasePrice ||
                                        parsedData.bidCalculatedBasePrice,
                                      values['OwnerHasEcoInvoice']
                                    ),
                              },
                              parsedData.includeInPrice &&
                                parsedData.monthlyPorterPrice > 0 && {
                                  service: 'Day Porter Included',
                                  value: formatPrice(
                                    isNumber(parsedData.porterOverride)
                                      ? parsedData.porterOverride
                                      : parsedData.monthlyPorterPrice
                                  ),
                                },
                              parsedData.costSupplies && {
                                service: 'Supplies Included',
                                value: formatPrice(parsedData.costSupplies),
                              },
                            ],
                            protectionOptions: [
                              ...(!parsedData.protectionIncludedInPrice
                                ? [
                                    {
                                      index: -1,
                                      total: 'Declined',
                                      plans: [],
                                    },
                                  ]
                                : []),
                              ...parsedData.protectionPackages,
                            ]
                              .filter(
                                (i) =>
                                  i.index ==
                                    props.selectedValues.page2
                                      .selectedProtection ||
                                  parsedData.protectionIncludedInPrice
                              )
                              .map((item) => ({
                                index: item.index,
                                value: parsedData.protectionIncludedInPrice
                                  ? 'Included'
                                  : item.index < 0
                                  ? item.total
                                  : formatPrice(
                                      item.totalOverride
                                        ? item.totalOverride
                                        : item.total
                                    ),
                                plans: item.plans.map((p) => ({
                                  plan: p.plan,
                                  frequency: p.frequency,
                                  color:
                                    optionsPlans.filter((i) => i.plan == p.plan)
                                      .length > 0
                                      ? optionsPlans.filter(
                                          (i) => i.plan == p.plan
                                        )[0].backgroundColor
                                      : null,
                                })),
                              })),
                          },
                        ]}
                      />
                    ) : (
                      <MonthlyServiceQuoteOptions
                        isHtml
                        selected
                        biggerThan768={equalsOrBiggerThan768}
                        hasDesinfection={
                          hasMonthlyFrequency
                            ? parsedData.secondMonthlyDisinfection
                            : parsedData.secondDisinfection
                        }
                        readyOnly
                        plusApplicableTax={franchiseValues.SalesTax}
                        containerStyle={{
                          width: 'calc(100% - 40px)',
                          paddingBottom: '20px',
                        }}
                        style={{
                          width: '100%',
                          backgroundColor: props.selectedValues.page2
                            .selectedFrequency
                            ? '#216EAB'
                            : '#e6e6e6',
                          borderRadius: '10px',
                        }}
                        color={
                          props.selectedValues.page2.selectedFrequency
                            ? 'white'
                            : '#515151'
                        }
                        selectedProtection={
                          props.selectedValues.page2.selectedProtection
                        }
                        calculatedTotal={frequencyOption.monthlyPrice}
                        hasBeta={values['OwnerHasEcoInvoice']}
                        optionBeta={discount}
                        packageIncluded={
                          parsedData.secondProtectionIncludedInPrice
                        }
                        items={[
                          {
                            title: `Quote 2: ${
                              parsedData.secondQuoteTitle ||
                              `${
                                hasMonthlyFrequency
                                  ? parsedData.secondCleaning
                                  : parsedData.secondFrequency.selecteds.length
                              }x ${toTitleCase(parsedData.frequencyService)}`
                            }`,
                            frequency: `${
                              hasMonthlyFrequency
                                ? parsedData.secondCleaning
                                : parsedData.secondFrequency.selecteds.length
                            }x ${toTitleCase(parsedData.frequencyService)}`,

                            hasDisinfection:
                              parsedData.secondProtectionIncludedInPrice ||
                              !declinedProtectionOption,
                            daysServiced: hasMonthlyFrequency
                              ? 'MONTHLY'
                              : data['SecondDaysCleanedListTextCompactText'],
                            timeWindow: hasMonthlyFrequency
                              ? parsedData.secondTimeWindow
                              : parsedData.secondFrequency.timeWindow,
                            services: [
                              {
                                service: 'Customized Cleaning Service',
                                value:
                                  parsedData.secondProtectionIncludedInPrice
                                    ? 'Included'
                                    : formatPrice(
                                        parsedData.secondBidOverrideBasePrice ||
                                          parsedData.secondBidCalculatedBasePrice,
                                        values['OwnerHasEcoInvoice']
                                      ),
                              },
                              parsedData.includeInPrice &&
                                parsedData.monthlyPorterPrice > 0 && {
                                  service: 'Day Porter Included',
                                  value: formatPrice(
                                    isNumber(parsedData.porterOverride)
                                      ? parsedData.porterOverride
                                      : parsedData.monthlyPorterPrice
                                  ),
                                },
                              parsedData.costSupplies && {
                                service: 'Supplies Included',
                                value: formatPrice(parsedData.costSupplies),
                              },
                            ],
                            protectionOptions: [
                              ...(!parsedData.secondProtectionIncludedInPrice
                                ? [
                                    {
                                      index: -1,
                                      total: 'Declined',
                                      plans: [],
                                    },
                                  ]
                                : []),
                              ...parsedData.secondProtectionPackages,
                            ]
                              .filter(
                                (i) =>
                                  i.index ==
                                    props.selectedValues.page2
                                      .selectedProtection ||
                                  parsedData.secondProtectionIncludedInPrice
                              )
                              .map((item) => ({
                                index: item.index,
                                value:
                                  parsedData.secondProtectionIncludedInPrice
                                    ? 'Included'
                                    : item.index < 0
                                    ? item.total
                                    : formatPrice(
                                        item.totalOverride
                                          ? item.totalOverride
                                          : item.total
                                      ),
                                plans: item.plans.map((p) => ({
                                  plan: p.plan,
                                  frequency: p.frequency,
                                  color:
                                    optionsPlans.filter((i) => i.plan == p.plan)
                                      .length > 0
                                      ? optionsPlans.filter(
                                          (i) => i.plan == p.plan
                                        )[0].backgroundColor
                                      : null,
                                })),
                              })),
                          },
                        ]}
                      />
                    )
                  ) : equalsOrBiggerThan768 ? (
                    //////////////////
                    <Layout
                      alignItems="flex-start"
                      color="#fff"
                      padding="20px"
                      width="100%"
                      marginLeft="0"
                      boxSizing="border-box"
                      backgroundColor="white"
                      paddingBottom="0 !important"
                      marginBottom="20px"
                      marginTop="0"
                    >
                      <Layout
                        flexDirection="row"
                        marginRight="0"
                        marginLeft="0"
                        marginBottom={'5px'}
                        width="100%"
                      >
                        <Block
                          style={{
                            paddingTop: values['OwnerHasEcoInvoice']
                              ? '10px'
                              : 'null',
                          }}
                        >
                          <BlockItem>
                            <H3Bold
                              color="#000"
                              maxFontSize="16px"
                              padding="5px"
                              textAlign="center"
                              data-cy="frequency-header"
                            >
                              Frequency
                            </H3Bold>

                            <Field
                              color="white"
                              style={{
                                height: hasDisinfection ? '67.5px' : 'auto',
                                height: discount ? '70px' : 'auto',
                                backgroundColor: '#216eab',
                              }}
                              data-cy="frequency-input"
                            >
                              <>
                                {frequencyOption.frequency}
                                {hasDisinfection && (
                                  <span
                                    style={{
                                      color: '#F7A11D',
                                      fontSize: '12px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    & Protection+ Disinfect
                                  </span>
                                )}
                              </>
                            </Field>
                            <DisclaimerParagraph
                              marginTop="5px"
                              marginBottom="5px"
                            />
                          </BlockItem>

                          <BlockItem>
                            <H3Bold
                              color="#000"
                              maxFontSize="16px"
                              padding="5px"
                              textAlign="center"
                              data-cy="days-serviced-header"
                            >
                              Days Serviced
                            </H3Bold>
                            <Field
                              color="white"
                              style={{
                                height: hasDisinfection ? '67.5px' : 'auto',
                                height: discount ? '70px' : 'auto',
                                backgroundColor: '#216eab',
                              }}
                              data-cy="days-serviced-input"
                            >
                              {frequencyOption.daysServiced}
                            </Field>
                            <DisclaimerParagraph
                              marginTop="5px"
                              marginBottom="5px"
                            />
                          </BlockItem>

                          <BlockItem>
                            <H3Bold
                              color="#000"
                              maxFontSize="16px"
                              padding="5px"
                              textAlign="center"
                              data-cy="time-window-header"
                            >
                              Time Window
                            </H3Bold>
                            <Field
                              color="white"
                              style={{
                                height: hasDisinfection ? '67.5px' : 'auto',
                                height: discount ? '70px' : 'auto',
                                backgroundColor: '#216eab',
                              }}
                              data-cy="time-window-input"
                            >
                              {frequencyOption.timeWindow}
                            </Field>
                            <DisclaimerParagraph
                              marginTop="5px"
                              marginBottom="5px"
                            />
                          </BlockItem>

                          <BlockItem
                            style={{ marginTop: isSmartCleanSet ? '-7px' : 0 }}
                          >
                            <H3Bold
                              textAlign="center"
                              color="#000"
                              maxFontSize="16px"
                              padding="5px"
                              data-cy="price-header"
                              marginTop={
                                values['OwnerHasEcoInvoice'] && discount
                                  ? '-24px'
                                  : 'null'
                              }
                            >
                              {isSmartCleanSet && (
                                <div>
                                  SmartClean<sup className="sm">&#8480;</sup>{' '}
                                  Price
                                  {franchiseValues &&
                                    franchiseValues.SalesTax && <sup>†</sup>}
                                </div>
                              )}

                              {!isSmartCleanSet && (
                                <div>
                                  Monthly Price
                                  {franchiseValues &&
                                    franchiseValues.SalesTax && (
                                      <small>
                                        <sup>†</sup>
                                      </small>
                                    )}
                                </div>
                              )}

                              {values['OwnerHasEcoInvoice'] && discount && (
                                <div style={{ color: '#219729' }}>
                                  Eco-Invoice <sup>&#8482;</sup>
                                </div>
                              )}
                            </H3Bold>
                            <Field
                              color="white"
                              data-cy="price-value"
                              style={{
                                height: hasDisinfection ? '67.5px' : 'auto',
                                height: discount ? '70px' : 'auto',
                                backgroundColor: '#216eab',
                              }}
                            >
                              {discount && (
                                <div>
                                  <span>
                                    <del style={{ fontSize: '14px' }}>
                                      {frequencyOption.monthlyPrice}
                                    </del>
                                  </span>
                                  <span
                                    style={{
                                      paddingLeft: '0px',
                                      color: '#98ff98',
                                    }}
                                  >
                                    <br />
                                    <strong>{`$${formatNumberByMask(
                                      (
                                        Number(
                                          frequencyOption.monthlyPrice
                                            .replace(',', '')
                                            .substr(1)
                                        ) -
                                        (values['OwnerHasEcoInvoice'] ? 5 : 0)
                                      )?.toFixed?.(2),
                                      {
                                        ...moneyMaskParams,
                                        thousandsSeparatorSymbol: ',',
                                      }
                                    )}`}</strong>
                                  </span>
                                </div>
                              )}
                              {!discount && (
                                <div>
                                  <span>{frequencyOption.monthlyPrice}</span>
                                </div>
                              )}
                            </Field>
                            {franchiseValues && franchiseValues.SalesTax && (
                              <DisclaimerParagraph
                                italic
                                textAlign={'right !important'}
                                width="100%"
                                marginTop="5px"
                                marginBottom="5px"
                                fontSize="11px"
                              >
                                <small className="tm">†</small> Plus Applicable
                                Sales Tax.
                              </DisclaimerParagraph>
                            )}
                          </BlockItem>
                        </Block>
                      </Layout>
                    </Layout>
                  ) : (
                    <></>
                  ))}

                {discount && (
                  <Layout
                    alignItems="start"
                    padding="20px"
                    paddingTop="0"
                    width="100%"
                    boxSizing="border-box"
                  >
                    <Paragraph
                      fontSize="14px"
                      textAlign="justify"
                      marginTop="0"
                    >
                      I hereby authorize Anago to debit my bank account for
                      payment of Services as detailed in the Service Procurement
                      Agreement. My bank account details are as follows:
                    </Paragraph>

                    <Debit ref={debitRef} values={values} />

                    <TermsAndConditions
                      values={values}
                      pageChanged={selectedItem}
                    />
                  </Layout>
                )}
              </RoundSubSection>
            )}
          {!isPhysicalContract && (
            <RoundSubSection
              marginTop="30px !important"
              paddingBottom="30px"
              width="100%"
              backgroundColor="#fff"
            >
              <SubSectionHeader
                alignItems={biggerThan768 ? 'start' : 'center'}
                backgroundColor="#216eab"
                color="#fff"
                padding={
                  equalsOrBiggerThan768 ? '15px 0 15px 0' : '20px 0 20px 0'
                }
              >
                <SectionTitle
                  maxFontSize="20px"
                  padding="0 20px 0 20px"
                  color="#fff"
                  margin="0 !important"
                >
                  Ready to Start?
                </SectionTitle>
              </SubSectionHeader>

              {/* <Signature ref={signatureRef} clickToSign={props.clickToSign} /> */}
              <button
                onClick={() => props.clickToSign()}
                disabled={declinedMonthlyOption && declinedSpecialtyServices}
                style={{
                  pointerEvents:
                    declinedMonthlyOption && declinedSpecialtyServices
                      ? 'none'
                      : 'auto',
                  padding: '15px',
                  margin: '30px',
                  marginBottom: 0,
                  borderRadius: '5px',
                  border: 'none',
                  backgroundColor:
                    declinedMonthlyOption && declinedSpecialtyServices
                      ? 'silver'
                      : '#F7A11D',
                  opacity:
                    declinedMonthlyOption && declinedSpecialtyServices
                      ? 0.6
                      : 1,
                  color: 'white',
                  width: '250px',
                  fontFamily: 'OpenSans-SemiBold',
                  letterSpacing: '0.5px',
                  cursor:
                    declinedMonthlyOption && declinedSpecialtyServices
                      ? 'auto'
                      : 'pointer',
                  fontSize: '16px',
                }}
                data-cy="click-here-to-sign"
              >
                CLICK HERE <br /> TO BEGIN SIGNING
              </button>
            </RoundSubSection>
          )}
          {isPhysicalContract && (
            <PhysicalContract
              update={props.update}
              data={props.data}
              clickToSign={props.clickToSign}
            />
          )}
        </GraySection>
      </Layout>
    </Layout>
  );
};

export default React.forwardRef(Page4);
