import {
  IBidFormHeader,
  IBidInfoForm,
  IBidClientInfo,
  IBidAccountInfo,
  IBidJanitorial,
  IBidServiceArea,
  IBidRoomInventory,
  IBidRoomInventoryItem,
  IBidRestroomFixture,
  IBidProductionRates,
  IBidDailyManHours,
  IBidCleaningSpecification,
  IBidCancellationClause,
  IBidDayPorter,
  IBidSupplies,
  IBidAdditionalQuotes,
  IBidFloorTypeTotals,
  IBidInformation,
  IBidAdditionalNote,
  IBidHotButtonItems,
  IBidTermsAndConditions,
  IBidProtectionSpecification,
  IBidProtectionDisinfectionPlans,
  IBidProtectionPackage,
  IBidProtectionPackagePlan,
} from './bidInterfaces';

export const generateArray = (length: number, value: any) =>
  [...Array(length).keys()].map((item) => value);

export const headerValues: IBidFormHeader = {
  cityId: undefined,
  pid: '',
  sid: '',
  control: '',
  bidDate: new Date(),
  status: 1,
  bidVersion: 2,
};

export const termsAndConditionsValues: IBidTermsAndConditions = {
  proposalSection4:
    'It is expressly agreed that Company, the Service Providers, and their respective employees and contractors will not, by virtue of providing the Services under this Agreement, be employees of Client, but are independent contractors and no withholding of Social Security, Federal, or State Income Tax or other deductions shall be made from the sums agreed to be paid by Client to Company herein.  All such payments are contract payments and not wages.',
  proposalSection5:
    'Client agrees that Company will have the sole discretion to select the Service Providers who will perform the Services. Client agrees that during the term of this Agreement and for 180 days after expiration or termination, it will not employ any Service Provider or any employee, agent, or associate of a Service Provider to perform the same or substantially the same type of Services. Company agrees that during the term of this Agreement and for 180 days after expiration or termination, it will not employ any employee, agent, or associate of Client. Client acknowledges and agrees that this Agreement may be assigned by Company to the Service Provider performing the Services, but, in that event, all payments to be made under this Agreement shall continue to be made to Company, as described herein, as the collection servicing agent of the Service Provider.',
  proposalSection7:
    'Client agrees and understands that it is impossible to determine the actual damages caused by its breach of this Agreement. Therefore, any amount due and owed under this Agreement for any remaining part of the term of this Agreement (as though the Agreement had not been terminated) shall be accelerated liquidated damages.',
  proposalSection8:
    'Client agrees not to withhold any portion of the monthly Agreement amount for any reason, unless prior written permission is obtained from Company, as the obligation to make payments hereunder is an independent obligation. In the event that enforcement of any obligation owed to Company, is placed in the hands of an attorney for collection, compromise or any other action, Client agrees to pay the reasonable attorney’s fees, cost and necessary disbursements, in addition to any other relief that may be granted.',
};

export const clientInfoValues: IBidClientInfo = {
  address: '',
  suite: '',
  city: '',
  state: '',
  postalCode: '',
  phone: '',
  mobilePhone: '',
  county: '',
  proposalAddressedTo: 'decisionMaker',
  walkThruName: '',
  walkThruEmail: '',
  walkThruTitle: '',
  decisionMakerName: '',
  decisionMakerEmail: '',
  decisionMakerTitle: '',
  sicId: null,
  sicName: 'null',
  externalId: '',
  waterStreetUpdateUrl: '',
  businessName: '',
  contactPhoneExtension: '',
  contactPhoneType: '',
  decisionMakerFirstName: '',
  decisionMakerLastName: '',
  decisionMakerPhone: '',
  decisionMakerPhoneExtension: '',
  decisionMakerPhoneType: '2',
  walkThruFirstName: '',
  walkThruLastName: '',
  walkThruPhone: '',
  walkThruPhoneExtension: '',
  walkThruPhoneType: '2',
};

export const clientInfoValuesInspect: IBidClientInfo = {
  AccountAddress: '',
  Suite: '',
  City: '',
  State: '',
  Zip: '',
  Phone: '',
  PobilePhone: '',
  County: '',
  WalkThruName: '',
  WalkThruEmail: '',
  WalkThruTitle: '',
  DecisionMakerName: '',
  DecisionMakerEmail: '',
  DecisionMakerTitle: '',
  SicId: null,
  SicName: 'null',
  ExternalId: '',
  WaterStreetUpdateUrl: '',
  AccountName: '',
  ContactPhoneExtension: '',
  ContactPhoneType: '',
  DecisionMakerFirstName: '',
  DecisionMakerLastName: '',
  DecisionMakerPhone: '',
  DecisionMakerPhoneExtension: '',
  DecisionMakerPhoneType: '2',
  WalkThruFirstName: '',
  WalkThruLastName: '',
  WalkThruPhone: '',
  WalkThruPhoneExtension: '',
  WalkThruPhoneType: '2',
};

export const accountInfoValues: IBidAccountInfo = {
  frequencyService: 'weekly',
  frequency: { selecteds: [], timeWindow: undefined },
  secondFrequency: { selecteds: [], timeWindow: undefined },
  addFrequency: false,
  smartClean: false,
  alarm: false,
  key: false,
  cleaning: 1,
  timeWindow: undefined,
};

export const janitorialValues: IBidJanitorial = {
  janitorialName: '',
  janitorialPrice: undefined,
  janitorialHours: undefined,
};

export const serviceAreaValues: IBidServiceArea = {
  serviceAreas: [],
  otherAreas: '',
};

export const inventoryBlankValue: IBidRoomInventoryItem = {
  roomType: '',
  floorType: '',
  length: undefined,
  width: undefined,
  totalSqft: undefined,
  prodGroup: 'A',
  fixtures: undefined,
};

export const roomInventoryValues: IBidRoomInventory = {
  inventory: generateArray(5, inventoryBlankValue),
};

export const floorTypeTotals: IBidFloorTypeTotals = {
  floorTypeTotals: [{ floorType: '', totalSqft: undefined }],
};

export const restroomFixturesValues: IBidRestroomFixture = {
  restroomFixtures: [],
};

export const productionRatesValues: IBidProductionRates = {
  productionRates: [],
};

export const dailyManHoursValues: IBidDailyManHours = {
  sqftOffice: undefined,
  sqftRestroom: undefined,
  sqftTotal: undefined,
  officeManHrs: undefined,
  RestroomManHours: undefined,
  totalManHrs: undefined,
  secondOfficeManHrs: undefined,
  totalSecondOfficeManHrs: undefined,
};

export const cleaningSpecificationValues: IBidCleaningSpecification = {
  facilityType: '',
  cleaningFrequency: {},
  specialNotes: [
    {
      description:
        'Service Providers to report any maintenance and/or security issues to the building manager.',
      additionalNotes: [''],
    },
  ],
};

export const protectionSpecificationValues: IBidProtectionSpecification = {
  protectionSpecification: {},
  protectionSpecificationSubtitle:
    'Performed with an EPA/CDC compliant disinfectant. Client will remove clutter from all surfaces needing disinfection prior to service. Note: disinfectant requires dwell time and may leave a slight haze on some surfaces.',
};

export const protectionDisinfectionPlansValues: IBidProtectionDisinfectionPlans =
  {
    protectionPackages: [],
    secondProtectionPackages: [],
    protectionIncludedInPrice: false,
    secondProtectionIncludedInPrice: false,
  };

export const protectionPackagePlanDefaultValue: IBidProtectionPackagePlan = {
  plan: undefined,
  frequency: undefined,
  sqFtApplied: undefined,
  prodRate: undefined,
  perHour: undefined,
  subtotal: undefined,
};

export const protectionPackageDefaultValue: IBidProtectionPackage = {
  index: undefined,
  plans: [protectionPackagePlanDefaultValue],
  total: undefined,
  totalOverride: undefined,
};

export const cancellationClauseValues: IBidCancellationClause = {
  lengthTerm: 'One Year',
  cancellationNotice: '60 Days',
  cancellationReason: 'Non-Performance',
  curePeriod: '15 Days',
};

export const hotButtonItemsValue: IBidHotButtonItems = {
  specialAttentionAreas: '',
};

export const dayPorterValues: IBidDayPorter = {
  porterDays: [],
  includeInPrice: true,
  porterHoursDay: undefined,
  porterTimeWindow: '',
  porterValueHour: undefined,
  monthlyPorterPrice: undefined,
  porterOverride: undefined,
};

export const suppliesValues: IBidSupplies = {
  suppliesLiners: 'Client Supplied',
  suppliesRestroom: 'Client Supplied',
  costSupplies: undefined,
};

export const additionalQuotesValues: IBidAdditionalQuotes = {
  quotes: [
    {
      perHour: false,
      perMonth: false,
      quoteOverride: '',
      quotePrice: undefined,
      quoteSelect: '',
      quoteSqft: '',
      quoteTotal: 0,
      serviceTitle: undefined,
      specialtyServiceQuoteOptionId: '0',
      serviceDescription: undefined,
      sqftApplied: '',
      sqftApplied_key: undefined,
      unit: false,
      unitLabel: undefined,
      unitPrice: undefined,
      uponRequest: false,
    },
  ],
  serviceFooter:
    'Client is responsible to remove and replace furniture and items from floor being serviced. A minimum charge  of $150 for the first hour plus an additional $25.00 per hour thereafter will apply if Service Provider must move items.',
};

export const bidInformationValues: IBidInformation = {
  bidPriceHour: undefined,
  secondCalculatedPrice: undefined,
  calculatedPrice: undefined,
  calculatedSmartCleanPrice: undefined,
  secondOverride: undefined,
  overridePrice: undefined,
  smartCleanOverridePrice: undefined,
  expirationDays: 90,
};

export const additionalNoteValues: IBidAdditionalNote = {
  additionalNote: '',
};

export const defaultValues: IBidInfoForm = {
  ...headerValues,
  ...clientInfoValues,
  ...accountInfoValues,
  ...janitorialValues,
  ...serviceAreaValues,
  ...roomInventoryValues,
  ...floorTypeTotals,
  ...restroomFixturesValues,
  ...productionRatesValues,
  ...dailyManHoursValues,
  ...cleaningSpecificationValues,
  ...protectionSpecificationValues,
  ...protectionDisinfectionPlansValues,
  ...cancellationClauseValues,
  ...hotButtonItemsValue,
  ...dayPorterValues,
  ...suppliesValues,
  ...additionalQuotesValues,
  ...bidInformationValues,
  ...additionalNoteValues,
  ...termsAndConditionsValues,
};

// local values
export const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const serviceAreas = [
  { value: 'Office', label: 'Office(s)' },
  { value: 'Exam Room', label: 'Exam Room(s)' },
  { value: 'Kitchen', label: 'Kitchen(s)' },
  { value: 'Restroom', label: 'Restroom(s)' },
  { value: 'Common Area', label: 'Common Area(s)' },
  { value: 'Stairwell', label: 'Stairwell(s)' },
  { value: 'Break Area', label: 'Break Area(s)' },
  { value: 'Lab', label: 'Lab(s)' },
  { value: 'Classroom', label: 'Classroom(s)' },
  { value: 'Elevator', label: 'Elevator(s)' },
  { value: 'Warehouse', label: 'Warehouse(s)' },
  { value: 'Showroom', label: 'Showroom(s)' },
  { value: 'Conference Room', label: 'Conference Room(s)' },
];

//pay attention, there is a rule on room inventory to split the abv from name.
//if you plan to change this, consider take a look on that first
export const roomTypes = [
  'Restroom',
  'EO-Executive Office',
  'GO-General Office',
  'CO-Common Office',
  'Conf. Room',
  'Kitchen',
  'Hall',
  'Exam Room',
  'Reception',
  'Lobby',
  'Stairwell',
  'Break Room',
  'Lab',
  'Classroom',
  'Elevator',
  'Warehouse',
];

//pay attention, there is a rule on room inventory to split the abv from name.
//if you plan to change this, consider take a look on that first
export const floorTypes = [
  'VCT – Vinyl Tile',
  'CRT – Concrete',
  'HWD – Hardwood',
  'CPT – Carpet',
  'LAM – Laminate',
  'CER – Ceramic',
  'MAR – Marble',
  'TRZ – Terrazzo',
  'RUB – Rubber',
  'LIN – Linoleum',
  'GRT – Granite Tile',
  'POR – Porcelain',
  'TRC – Terracotta',
  'LVT – Luxury Vinyl Tile',
  'QRT – Quarry Tile Flooring',
  'GCT – Glazed Ceramic Tile',
  'EPX – Epoxy',
  'SLT – Slate Tile',
  'LWF – Laminate Wood Flooring',
].sort();

export const prodGroups = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'RR1',
  'RR2',
  'RR3',
];

export const oneDayFacilityTypes = [
  'Apartment',
  'Auto Dealer',
  'Bowling Alley',
  'Country Club',
  'Day Care',
  'Day Spa',
  'Dental Office',
  'Fitness Center',
  'General Minimum',
  'General Office',
  'Manufacturing',
  'Medical Office',
  'Movie Theater',
  'Multi Tenant',
  'Place of Worship',
  'Restaurant',
  'Retail Grocery',
  'School',
  'Surgical Center',
];

export const moreDaysWeeklyFacilityTypes = [
  'Apartment',
  'Auto Dealer',
  'Bowling Alley',
  'Country Club',
  'Day Care',
  'Day Spa',
  'Dental Office',
  'Fitness Center',
  'General Office',
  'Manufacturing',
  'Medical Office',
  'Movie Theaters',
  'Apartment',
  'Multi Tenant',
  'Place of Worship',
  'Restaurant',
  'Retail Grocery',
  'School',
  'Surgical Center',
];

//
export const monthlyFacilityTypes = ['General Office'];

export const lengthTermOptions = [
  'Month to Month',
  'Six Months',
  'One Year',
  'Two Years',
  'Three Years',
];

export const cancellationNoticesOptions = [
  '30 Days',
  '45 Days',
  '60 Days',
  '90 Days',
];

export const cancellationReasonOptions = ['Non-Performance', 'Any Reason'];

export const curePeriodOptions = ['No Cure', '3 Days', '7 Days', '15 Days'];

export const suppliesOptions = [
  'Client Supplied',
  'Included in Price',
  'Billed Separately',
];

export const quotesOptions = [
  'Acid Wash & Grout Cleaning Ceramic Floor',
  'Bonnet Carpet Cleaning',
  'Burnish Vinyl Floor',
  'Comprehensive Detail Clean',
  'External Window Washing',
  'Hot Water Carpet Extraction',
  'Internal Window Washing',
  'Machine Scrub Ceramic Floor',
  'Scrub & Recoat Vinyl Floor',
  'Strip, Seal & Refinish Vinyl Floor',
  'Emergency Services (Floods, etc.)',
];

export const quotesGroupOptions = [
  { label: '$ /Sq. Ft.', value: '0' },
  { label: '$ /Hour', value: '1' },
  { label: '$ /Month', value: '3' },
  { label: 'Unit Price', value: '2' },
  { label: 'Upon Request', value: '-1' },
];

export const optionsPlans = [
  { plan: 'BLUE', color: '#47A2DA', backgroundColor: '#216EAB', subtitle: '' },
  {
    plan: 'ORANGE',
    color: '#F7A11D',
    backgroundColor: '#F7A11D',
    subtitle: 'Includes everything in the BLUE plan and...',
  },
  {
    plan: 'PLATINUM',
    color: '#454D57',
    backgroundColor: '#454D57',
    subtitle: 'Includes everything in the ORANGE plan and...',
  },
];

export const defaultPostPayload = {
  // BidUniqueID: '',
  DomainLinersId: '',
  DomainRestroomId: '',
  JanitorialCompanyNow: '',
  CurrentlyPaying: '',
  JanitorialCompanyName: '',
  DailyHoursCurrentService: '',
  BidAmount: '',
  SecondBidAmount: '',
  SmartCleanBidAmount: '',
  Initial: '',
  AnagoManHours: '',
  CostOfSupplies: '',
  CostOfDayPorter: '',
  DayPorterHoursPerDay: '',
  DayPorterMoneyPerHour: '',
  DayPorterTimeWindow: '',
  Notes: '',
  CreatedBy: '',
  BidSpecType: '',
  FacilityType: '',
  Status: 1,
  AddressedToWalkThru: '',
  UserEditing: '',
  TotalSqrFeet: '',
  RestroomSqrFeet: '',
  ProductionRate: '',
  MinutesPerFixture: '',
  RestroomFixtures: '',
  BidAmountPerHour: '',
  BidAmountOverride: '',
  SecondBidAmountOverride: '',
  SmartCleanBidAmountOverride: '',
  DaysWeekCleaned: '',
  DaysPotterMonday: '',
  DaysPotterTuesday: '',
  DaysPotterWednesday: '',
  DaysPotterThursday: '',
  DaysPotterFriday: '',
  DaysPotterSaturday: '',
  DaysPotterSunday: '',
  DaysPotter: '',
  OfficeManHours: '',
  SecondOfficeManHours: '',
  RestroomManHours: '',
  DailyManHours: '',
  TotalDailyManHrs: '',
  SecondTotalDailyManHrs: '',
  MonthlyMenHours: '',
  ReviewServiceCommencementDate: '',
  ReviewEmergencyContactName: '',
  ReviewEmergencyContactPhone: '',
  ReviewBillingAddress: '',
  ReviewBillingCity: '',
  ReviewBillingState: '',
  ReviewBillingPostalCode: '',
  ReviewPayableContactName: '',
  ReviewPayableContactPhone: '',
  ReviewPayableContactEmail: '',
  ReviewSignatureImageId: '',
  DomainBidInfoSheetStatusId: '',
  ReviewInitialsImageId: '',
  Room: [],
  ProductionRates: [],
  Floor: [],
  AdditionalQuotes: [],
  NightlyServiceAreas: [],
  NightlyRestroomServices: [],
  NightlyMonthlyServices: [],
  NightlyWeeklyServices: [],
  WeeklyServiceAreas: [],
  WeeklyRestroomServices: [],
  WeeklyMonthlyServices: [],
  Frequencies: [],
  SecondFrequencyPriceSelected: false,
  IncludeDayPorterInPrice: false,
  SentStatus: false,
  IsTemplate: false,
  AddRRFixture: false,
  SecondMinutesPerFixture: '',
  SecondRestroomFixtures: '',
  IsMonthlyCleaning: false,
  LenghtOfTerm: '',
  CancellationNotice: '',
  CancellationReason: '',
  CurePeriod: '',
  WalkThruContact: '',
  SecondTimeOfDay: '',
  IsTaxExempt: false,
  TaxExemptionNumber: '',
  ProposalHolidays: '',
  ProposalSection3: '',
  ProposalSection4: '',
  ProposalSection5: '',
  ProposalSection7: '',
  ProposalSection8: '',
  SelectedFrequency: null,
  PrintPDF: false,
  FranchiseId: '',
  BidInformationSheetId: '',
  OriginalBidInformationSheetId: '',
  SecondDaysCleanedMonday: '',
  SecondDaysCleanedTuesday: '',
  SecondDaysCleanedWednesday: '',
  SecondDaysCleanedThursday: '',
  SecondDaysCleanedFriday: '',
  SecondDaysCleanedSaturday: '',
  SecondDaysCleanedSunday: '',
  GenerateAttachmentsPDF: false,
  SelectedAttachments: '',
  ExistentClient: false,
};
